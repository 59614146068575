import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LofinoInputField } from "../../../../data/models/lofino-input-field";
import Error from "../default/Error";
import Label from "../default/Label";
import "./Date.input.scss";

interface OwnProps extends LofinoInputField {
  lines?: "full" | "inset" | "none";
}

const DateInput: React.FC<OwnProps> = ({
  label,
  info,
  valueKey,
  defaultValue,
  lines,
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [date, setDate] = useState<Date>(new Date(defaultValue) || new Date());
  const [presentSelecet, setPresentSelect] = useState(false);
  return (
    <IonItem lines={lines}>
      <IonGrid>
        <IonRow>
          <Label label={label} info={info} valueKey={valueKey} />
          <IonCol size="12" sizeLg="6">
            <IonInput
              disabled={true}
              value={date.toLocaleDateString()}
              onClick={() => setPresentSelect((cur) => !cur)}
            />
          </IonCol>
          {errors[valueKey.toString()] && (
            <IonCol size="12">
              <Error name={valueKey.toString()} />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      <IonModal
        className="data-date-input-modal"
        isOpen={presentSelecet}
        backdropDismiss={false}
      >
        <IonContent forceOverscroll={false}>
          <Controller
            name={valueKey.toString()}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value, onChange, name } }) => {
              return (
                <IonDatetime
                  key={name}
                  presentation="date"
                  value={date.toISOString()}
                  onIonChange={({ detail: { value } }) => {
                    const selectedValue = Array.isArray(value)
                      ? value[0]
                      : value;
                    if (selectedValue) {
                      setDate(new Date(selectedValue));
                      onChange(
                        new Date(selectedValue).toISOString().split("T")[0]
                      );
                    }
                  }}
                  //size="cover"
                  //style={{ height: "100%" }}
                  className="ion-text-center"
                >
                  <div slot="title">Datum wählen</div>
                </IonDatetime>
              );
            }}
          />
          <IonButton
            expand="block"
            onClick={() => setPresentSelect((cur) => !cur)}
          >
            <IonLabel>Okay</IonLabel>
          </IonButton>
        </IonContent>
      </IonModal>
    </IonItem>
  );
};

export default DateInput;

/*
old buttons in date-time picker
                  <IonButtons slot="buttons">
                    <IonButton />
                    <IonButton
                      slot="end"
                      onClick={() => setPresentSelect((cur) => !cur)}
                    >
                      <IonLabel>Okay</IonLabel>
                    </IonButton>
                  </IonButtons>
*/
