interface QueryParam {
    value: string
    used: boolean
}

type GlobalQueryParams = Record<string, string>

var GLOBAL_QUERY_PARAMS: GlobalQueryParams = {}

var used: Record<string, boolean> = {}

export function initQueryParams() {
    GLOBAL_QUERY_PARAMS = Object.fromEntries(
        new URLSearchParams(window.location.search),
    )
}

export function getQueryParam(param: string) {
    const val = GLOBAL_QUERY_PARAMS[param]
    //console.log('searched for', param, val)
    if (val) used[param] = true
    return val
}

export function allQueryParamsUsed() {
    return true
}
