import { AlertOptions } from '@ionic/core'
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions'
import i18next from 'i18next'

const GlobalAlerts = {
    submitUsernameError: (): AlertOptions & HookOverlayOptions => {
        const t = i18next.getFixedT(null, 'responses', 'emailError')
        return {
            header: t('header'),
            message: t('message'),
            buttons: [{ text: t('ack'), role: 'submit' }],
        }
    },

    submitUsernameSuccess: (
        email: string,
    ): AlertOptions & HookOverlayOptions => {
        const t = i18next.getFixedT(null, 'responses', 'emailSuccess')
        return {
            header: t('header', { email }),
            message: t('message'),
            buttons: [{ text: t('ack'), role: 'submit' }],
        }
    },

    submitCodeError: (): AlertOptions & HookOverlayOptions => {
        const t = i18next.getFixedT(null, 'responses', 'codeError')
        return {
            header: t('header'),
            message: t('message'),
            buttons: [{ text: t('retry'), role: 'submit' }],
        }
    },

    loadCustomerDataError: (): AlertOptions & HookOverlayOptions => {
        //const t = i18next.getFixedT(null, "responses", "codeError");
        return {
            header: 'Kundendaten konnten nicht geladen werden.',
            message: 'Dein Unternehmen ist bei uns noch nicht registriert.',
            buttons: [{ text: 'Okay', role: 'submit' }],
        }
    },

    loadCustomerDataStandard: (): AlertOptions & HookOverlayOptions => {
        //const t = i18next.getFixedT(null, "responses", "codeError");
        return {
            header: 'Der Standard LOFINO Vorteilsrechner wurde geladen.',
            message: 'Dein Unternehmen ist bei uns noch nicht registriert.',
            buttons: [{ text: 'Okay', role: 'submit' }],
        }
    },

    userLoggedOut: (): AlertOptions & HookOverlayOptions => {
        const t = i18next.getFixedT(null, 'responses', 'userLoggedOut')
        return {
            header: t('header'),
            buttons: [{ text: t('ack'), role: 'submit' }],
        }
    },

    userMustReAuthenticate: (): AlertOptions & HookOverlayOptions => {
        const t = i18next.getFixedT(null, 'responses', 'userMustReAuthenticate')
        return {
            header: t('header'),
            buttons: [{ text: t('ack'), role: 'submit' }],
        }
    },
}

export default GlobalAlerts
