import { IonCol, IonGrid, IonItem, IonRow, IonText } from '@ionic/react'
import React, { useMemo } from 'react'
import { BenefitField } from '../../../../data/models/benefit'
import Label from '../default/Label'

const ValueOutput: React.FC<{ benefit: BenefitField }> = ({ benefit }) => {
    const [newVal, oldVal] = useMemo(() => {
        const unit = benefit.unit || ''
        return [
            benefit.newValue !== null
                ? typeof benefit.newValue === 'number'
                    ? benefit.newValue.toFixed(2)
                    : benefit.newValue
                : '',
            benefit.oldValue !== null
                ? typeof benefit.oldValue === 'number'
                    ? benefit.oldValue.toFixed(2)
                    : benefit.oldValue
                : '',
        ].map((val) => (val ? val + ' ' + unit : ''))
    }, [benefit.newValue, benefit.oldValue, benefit.unit])

    /**
     * fontWeight: "bold",
     * color: "green" // "--ion-color-success"
     */
    return (
        <IonItem lines="none" className="ion-no-padding">
            <IonGrid className="ion-no-padding">
                <IonRow
                //style={{ borderBottom: '1px solid grey', margin: 10 }}
                >
                    <Label
                        {...benefit}
                        responsive={false}
                        alertHeader="Hinweis"
                        style={benefit.style}
                    />
                    <IonCol size="3" className="ion-text-right">
                        <IonText style={benefit.style}>{oldVal}</IonText>
                    </IonCol>
                    <IonCol size="3" className="ion-text-right">
                        <IonText style={benefit.style}>{newVal}</IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonItem>
    )
}

export default ValueOutput
