import { Storage } from '@capacitor/storage'
import { AnySchema } from 'yup'
import { StateContext } from '../AppContext'
import { ResponseMessage } from '../models/ResponseMessage'
import { AuthState } from '../user/user.state'
import { BACKEND_URL } from './constants'
import { getQueryParam } from './queryparams'
interface ApiOptions {
    validationSchema?: AnySchema
    anonymus?: boolean
}

const FETCH = async <T = any>(
    input: RequestInfo,
    init: RequestInit & ApiOptions = {},
): Promise<T> => {
    const { validationSchema, anonymus = false, ..._init } = init

    var options: RequestInit = {
        ..._init,
        headers: {
            ..._init.headers,
            'Content-Type': 'application/json',
        },
    }

    if (anonymus === false) {
        const token =
            getQueryParam('access_token') ||
            (await Storage.get({ key: 'token' })).value
        if (!token)
            throw new ResponseMessage({
                headers: null!,
                text: 'token not found',
            })
        options.headers = {
            ...options.headers,
            Authorization: 'Bearer ' + token,
        }
    }

    try {
        const res = await fetch(input, options)

        if (res.status.toString() == '401') {
            StateContext.dispatch({
                type: 'set-authstate',
                state: AuthState.Reauthentication,
            })
        }

        if (res.ok === false)
            throw new ResponseMessage({
                headers: res.headers,
                text: 'Response was not ok.',
            })

        try {
            const json = await res.json()
            if (validationSchema) {
                try {
                    return validationSchema.validateSync(json) as T
                } catch (e: any) {
                    console.log(
                        'error when validating parsed json with yup schema',
                        e,
                    )
                    throw new ResponseMessage({
                        headers: res.headers,
                        text: e.message,
                    })
                }
            }
            return json as T
        } catch (e) {
            console.log('error when parsing json with res.json()', e)
            throw new ResponseMessage({ headers: null!, text: e as any })
        }
    } catch (e) {
        if (e instanceof ResponseMessage) throw new ResponseMessage(e)
        throw new ResponseMessage({ headers: null!, text: e as any })
    }
}

const GET = <T = any>(route: string, options: ApiOptions = {}) =>
    FETCH<T>(BACKEND_URL + route, {
        ...options,
        method: 'GET',
    })

const PUT = <T = any>(
    route: string,
    options: ApiOptions & { body?: any } = {},
) =>
    FETCH<T>(BACKEND_URL + route, {
        ...options,
        method: 'PUT',
        body: options.body
            ? typeof options.body === 'string'
                ? options.body
                : JSON.stringify(options.body)
            : undefined,
    })

const POST = <T = any>(
    route: string,
    options: ApiOptions & { body?: any } = {},
) =>
    FETCH<T>(BACKEND_URL + route, {
        ...options,
        method: 'POST',
        body: options.body
            ? typeof options.body === 'string'
                ? options.body
                : JSON.stringify(options.body)
            : undefined,
    })

export default { GET, PUT, POST }
