import { IonApp, IonContent, IonLoading, IonPage } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const I18nBoundary: React.FC = ({ children }) => {
    const { ready } = useTranslation()
    if (ready) return <>{children}</>
    return (
        <IonApp>
            <IonPage>
                <IonContent>
                    <IonLoading isOpen={true} />
                </IonContent>
            </IonPage>
        </IonApp>
    )
}

export default I18nBoundary
