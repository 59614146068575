export const Logo = () => (
  <svg
    width="110"
    height="23"
    viewBox="0 0 110 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_24_11)">
      <path
        d="M4.21363 18.3712H14.2615V22.065H0V0.935028H4.21363V18.3712Z"
        fill="#0F2830"
      />
      <path
        d="M54.129 4.6288H43.3518V10.1099H52.7109V13.7639H43.3518V22.065H39.1382V0.935028H54.0885V4.6288H54.129Z"
        fill="#0F2830"
      />
      <path
        d="M61.8269 0.935028V22.065H57.6133V0.935028H61.8269Z"
        fill="#0F2830"
      />
      <path
        d="M80.9096 0.935028H85.0828V22.065H81.1933L72.2798 9.951C71.429 8.8389 70.6997 7.7268 70.5376 7.32962L70.4566 7.36933C70.5782 7.80623 70.6592 9.07721 70.6592 10.4673V22.065H66.4456V0.935028H70.3351L79.2485 13.049C80.0993 14.1611 80.8286 15.2732 80.9907 15.6704L81.0717 15.6307C80.9502 15.1938 80.8691 13.9228 80.8691 12.5327V0.935028H80.9096Z"
        fill="#0F2830"
      />
      <path
        d="M26.0921 22.065C20.1363 22.065 15.3149 17.3385 15.3149 11.5C15.3149 5.66146 20.1363 0.935028 26.0921 0.935028C32.0479 0.935028 36.8693 5.66146 36.8693 11.5C36.8693 17.3385 32.0074 22.065 26.0921 22.065ZM26.0921 5.06569C22.4862 5.06569 19.5286 7.9651 19.5286 11.5C19.5286 15.0349 22.4862 17.9343 26.0921 17.9343C29.698 17.9343 32.6556 15.0349 32.6556 11.5C32.6556 7.9651 29.698 5.06569 26.0921 5.06569Z"
        fill="url(#paint0_linear_24_11)"
      />
      <path
        d="M33.9927 4.35077C33.9927 4.35077 33.9927 4.31105 33.9522 4.31105C33.1014 3.39754 30.4679 0.974746 26.1327 0.935028C21.757 0.935028 19.1235 3.35782 18.2321 4.31105C18.2321 4.35077 18.1916 4.35077 18.1916 4.35077C17.4218 5.14513 16.8141 9.99072 17.6244 10.7851C18.0296 11.1823 18.3132 11.3411 18.5563 11.3411C19.1235 11.3411 19.4881 10.7851 19.5691 10.7056C19.6097 10.229 19.7717 9.47439 20.1769 8.68003C20.42 8.16369 20.7846 7.60764 21.1898 7.21046L21.2708 7.13103C21.4734 6.93244 23.094 5.10541 26.0922 5.06569C28.7257 5.06569 30.792 7.05159 30.954 7.21046L30.9946 7.25018C31.8049 8.04454 33.1419 8.04454 33.9927 7.25018C34.7625 6.45582 34.803 5.18485 33.9927 4.35077Z"
        fill="url(#paint1_linear_24_11)"
      />
      <path
        d="M99.0203 22.065C93.0645 22.065 88.2432 17.3385 88.2432 11.5C88.2432 5.66146 93.105 0.935028 99.0203 0.935028C104.976 0.935028 109.797 5.66146 109.797 11.5C109.797 17.3385 104.976 22.065 99.0203 22.065ZM99.0203 5.06569C95.4144 5.06569 92.4568 7.9651 92.4568 11.5C92.4568 15.0349 95.4144 17.9343 99.0203 17.9343C102.626 17.9343 105.584 15.0349 105.584 11.5C105.584 7.9651 102.626 5.06569 99.0203 5.06569Z"
        fill="url(#paint2_linear_24_11)"
      />
      <path
        d="M106.961 4.35077C106.961 4.35077 106.961 4.31105 106.921 4.31105C106.03 3.35782 103.396 0.935028 99.0609 0.935028C94.6852 0.935028 92.0517 3.35782 91.1604 4.31105C91.1604 4.35077 91.1198 4.35077 91.1198 4.35077C90.35 5.14513 89.7423 9.99072 90.5526 10.7851C90.9578 11.1823 91.2414 11.3411 91.4845 11.3411C92.0517 11.3411 92.4163 10.7851 92.4974 10.7056C92.5379 10.229 92.7 9.47439 93.1051 8.68003C93.3482 8.16369 93.7128 7.60764 94.118 7.21046L94.199 7.13103C94.4016 6.93244 96.0222 5.10541 99.0204 5.06569C101.654 5.06569 103.72 7.05159 103.882 7.21046L103.923 7.25018C104.733 8.04454 106.07 8.04454 106.921 7.25018C107.731 6.45582 107.731 5.18485 106.961 4.35077Z"
        fill="url(#paint3_linear_24_11)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_24_11"
        x1="40.4242"
        y1="15.0621"
        x2="5.62785"
        y2="6.07325"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0220619" stop-color="#0F2830" stop-opacity="0.05" />
        <stop offset="0.0604895" stop-color="#0F2830" stop-opacity="0.05" />
        <stop offset="0.4319" stop-color="#0F2830" stop-opacity="0.5" />
        <stop offset="0.5617" stop-color="#0F2830" stop-opacity="0.85" />
        <stop offset="0.6701" stop-color="#0F2830" />
        <stop offset="0.9445" stop-color="#0F2830" />
        <stop offset="1" stop-color="#0E5E6F" />
        <stop offset="1" stop-color="#0F2830" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24_11"
        x1="17.2389"
        y1="6.14559"
        x2="34.5851"
        y2="6.14559"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.036892" stop-color="#0F2830" stop-opacity="0.05" />
        <stop offset="0.2386" stop-color="#0F2830" stop-opacity="0.9" />
        <stop offset="0.4387" stop-color="#0F2830" />
        <stop offset="0.9445" stop-color="#0F2830" />
        <stop offset="1" stop-color="#0F2830" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_24_11"
        x1="113.371"
        y1="15.0621"
        x2="78.5743"
        y2="6.07325"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0220619" stop-color="#0F2830" stop-opacity="0.05" />
        <stop offset="0.0604895" stop-color="#0F2830" stop-opacity="0.05" />
        <stop offset="0.4319" stop-color="#0F2830" stop-opacity="0.5" />
        <stop offset="0.5617" stop-color="#0F2830" stop-opacity="0.85" />
        <stop offset="0.6701" stop-color="#0F2830" />
        <stop offset="0.9445" stop-color="#0F2830" />
        <stop offset="1" stop-color="#0F2830" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_24_11"
        x1="90.1853"
        y1="6.14559"
        x2="107.532"
        y2="6.14559"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.036892" stop-color="#0F2830" stop-opacity="0.05" />
        <stop offset="0.2386" stop-color="#0F2830" stop-opacity="0.9" />
        <stop offset="0.4387" stop-color="#0F2830" />
        <stop offset="0.9445" stop-color="#0F2830" />
        <stop offset="1" stop-color="#0F2830" />
      </linearGradient>
      <clipPath id="clip0_24_11">
        <rect
          width="110"
          height="21.1299"
          fill="white"
          transform="translate(0 0.935028)"
        />
      </clipPath>
    </defs>
  </svg>
);
