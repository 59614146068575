import React from 'react'
import { ContextType } from '../util/types'
import FormularState, { initialFormularState } from './formular.state'

const FormularContext = React.createContext<ContextType<FormularState>>({
    state: initialFormularState,
    dispatch: () => undefined,
})

export default FormularContext
