import { IonCol, IonRow, IonText } from '@ionic/react'
import React from 'react'

interface OwnProps {
    label: string
    color?: string
}

const Header: React.FC<OwnProps> = ({ label, color }) => {
    return (
        <IonRow style={{ color: color }}>
            <IonCol className="ion-text-center">
                <IonText style={{ fontWeight: 'bold', fontSize: 20 }}>
                    {label}
                </IonText>
            </IonCol>
        </IonRow>
    )
}

export default Header
