//export const BACKEND_URL = 'http://localhost:8080'

//export const BACKEND_URL =("http://benefitnest-env.eba-dvxdyqjn.eu-central-1.elasticbeanstalk.com");
//export const BACKEND_URL = 'https://lofino-benefit-backend-2.azurewebsites.net'
// export const BACKEND_URL =
//     process.env.NODE_ENV === 'development'
//         ? process.env.REACT_APP_DEVELOPMENT_BACKEND
//         : window.location.href.startsWith('https://vorteilsrechner.lofino.de')
//         ? process.env.REACT_APP_PRODUCTIVE_BACKEND
//         : process.env.REACT_APP_DEVELOPMENT_BACKEND

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ''
