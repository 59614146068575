import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import React from 'react'
import './Info.scss'

const Info: React.FC<{
    defaultValue?: string | number
    label?: string
}> = ({ defaultValue = '', label = '' }) => {
    return (
        <>
            <div className="highlighter" />
            <IonGrid>
                <IonRow>
                    {/* <IonCol
                        size="1"
                        style={{ margin: 'auto' }}
                        className="ion-text-left ion-no-padding"
                    >
                        <IonIcon
                            className="icon"
                            size="large"
                            icon={informationCircleOutline}
                        />
                    </IonCol> */}
                    <IonCol size="10">
                        <IonRow>
                            <IonText>{label}</IonText>
                        </IonRow>
                        <IonRow>
                            <IonText className="value">{defaultValue}</IonText>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    )
}

export default Info
