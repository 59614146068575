import { TextFieldTypes } from "@ionic/core";
import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useContext, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormularContext from "../../../../data/formular/formular.context";
import { LofinoInputField } from "../../../../data/models/lofino-input-field";
import {
  ValidateDependency,
  ValidateNumber,
} from "../../../../data/util/validator";
import Error from "../default/Error";
import Label from "../default/Label";
import "./Value.input.scss";

const getIonInputType = (t: string): TextFieldTypes => {
  switch (t) {
    case "float":
      return "number";
    case "string":
      return "text";
    case "boolean":
      return "text";
    default:
      return "text";
  }
};

interface OwnProps extends LofinoInputField {
  disabled?: boolean;
  lines?: "full" | "inset" | "none";
}

const ValueInput: React.FC<OwnProps> = ({
  valueKey,
  label,
  info,
  defaultValue,
  min,
  max,
  type,
  unit = "",
  budgetDependencyValueKey,
  lines,
  disabled,
}) => {
  const {
    state: { blocks },
  } = useContext(FormularContext);
  const dependencyField = useMemo(() => {
    if (!budgetDependencyValueKey) return undefined;
    for (const block of blocks)
      for (const field of block.inputs)
        if (field.valueKey.toString() === budgetDependencyValueKey.toString())
          return field;
    return undefined;
  }, [blocks, blocks.length, budgetDependencyValueKey]);
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useFormContext();
  useMemo(() => {
    if (valueKey === 3 || label === "Steuerklasse")
      console.log(valueKey, label);
  }, [valueKey, label]);
  return (
    <IonItem lines={lines} className="ion-no-padding">
      <IonGrid>
        <IonRow>
          <Label label={label} info={info} valueKey={valueKey} />
          <Controller
            name={valueKey.toString()}
            /**
             * Am besten schreibst du dahinter der wert muss zwischen x und y liegen
             */
            rules={{
              required: "Dieses Feld muss ausgefüllt sein",
              min:
                min !== undefined
                  ? {
                      value: min,
                      message: `Ihre Eingabe ist zu klein. Ihre Eingabe muss zwischen ${min} und ${max} liegen!`,
                    }
                  : undefined,
              max:
                max !== undefined
                  ? {
                      value: max,
                      message: `Ihre Eingabe ist zu klein. Ihre Eingabe muss zwischen ${min} und ${max} liegen!`,
                    }
                  : undefined,
              minLength: 1,
              //   validate: {
              //     ...(getIonInputType(type) === "number" ? ValidateNumber() : {}),
              //     ...ValidateDependency(valueKey.toString(), blocks, getValues),
              //   },
            }}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, name, value } }) => {
              const t = getIonInputType(type);
              return (
                <IonCol
                  key={"data-input-" + valueKey}
                  size="11"
                  sizeLg="5"
                  className="ion-text-right"
                >
                  <IonInput
                    disabled={disabled}
                    name={name}
                    value={value}
                    onIonChange={({ detail: { value } }) => {
                      const val =
                        t === "number" ? value?.split(",").join(".") : value;

                      onChange(val);
                      if (budgetDependencyValueKey)
                        trigger([budgetDependencyValueKey.toString()]);
                    }}
                    debounce={10}
                    className={
                      "data-input-border-radius data-input-border" +
                      (errors[valueKey] ||
                      (budgetDependencyValueKey &&
                        errors[budgetDependencyValueKey.toString()])
                        ? "-danger"
                        : "")
                    }
                    style={{
                      height: "2rem",
                    }}
                  />
                </IonCol>
              );
            }}
          />
          <IonCol
            className="ion-text-center"
            size="1"
            style={{ margin: "auto" }}
          >
            <div style={{ height: "100%" }}>
              <IonText style={{ verticalAlign: "middle" }}>{unit}</IonText>
            </div>
          </IonCol>

          {errors[valueKey.toString()]?.type !== "dependency" && (
            <IonCol>
              <Error name={valueKey.toString()} />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      {/* </IonCardContent> */}
    </IonItem>
  );
};

export default ValueInput;
