import { SystemActions } from './system.actions'
import { SystemState } from './system.state'

export function systemReducer(
    state: SystemState,
    action: SystemActions,
): SystemState {
    switch (action.type) {
        case 'system-state-alert': {
            if (state.alerts.includes(action.alert) === false)
                state.alerts.push(action.alert)
            return state
        }
        case 'system-state-toast': {
            if (state.toasts.includes(action.toast) == false)
                state.toasts.push(action.toast)
            return state
        }
        case 'system-state-remove-message': {
            switch (action._type) {
                case 'alert':
                    state.alerts.shift()
                    break
                case 'toast':
                    state.toasts.shift()
                    break
            }
            return state
        }
        case 'system-state-error': {
            return { ...state, error: action.error }
        }
    }
}
