import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

function useSearchParam(name: string) {
    const { search } = useLocation()
    const [param, setParam] = useState(new URLSearchParams(search).get(name))
    useEffect(() => {
        setParam(new URLSearchParams(search).get(name))
    }, [search, setParam])
    return useMemo(() => param, [param])
}

export default useSearchParam
