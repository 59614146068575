import i18next from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import App from './App'
import I18nBoundary from './components/i18nBoundary'
import './data/util/i18next'
import './data/util/queryparams'
import reportWebVitals from './util/reportWebVitals'
import * as serviceWorkerRegistration from './util/serviceWorkerRegistration'

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <I18nextProvider i18n={i18next}>
                <I18nBoundary>
                    <App />
                </I18nBoundary>
            </I18nextProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
