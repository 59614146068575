import {
  IonCardHeader,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CookieMessage from "../../components/CookieMessage";
import {
  logout,
  submitCode,
  submitUsername,
} from "../../data/user/user.actions";
import { AuthState, UserState } from "../../data/user/user.state";
import { connect } from "../../data/util/connect";
import useToggle from "../../hooks/useToggle";
import CodeInput from "./components/Code.input";
import MailInput from "./components/Mail.input";
import styled from "styled-components";
import { Logo } from "../../assets/logo";

interface StateProps extends UserState {}

interface DispatchProps {
  submitCode: typeof submitCode;
  submitUsername: typeof submitUsername;
  logout: typeof logout;
}

const LoginPage: React.FC<StateProps & DispatchProps> = ({
  authState,
  username,
  submitCode,
  submitUsername,
  logout,
}) => {
  const [value, setValue] = useState<{ mail?: string; code?: string }>({
    mail: username,
    code: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [hasAgreedToAgb, setHasAgreedToAgb] = useState(
    //localStorage.getItem('agb-agreed') === 'true',
    authState === AuthState.Reauthentication || false
  );
  const [checkboxChecked, toggleCheckbox] = useToggle(false);
  const [cookiesOk, setCookiesOk] = useState(false);

  const { i18n } = useTranslation("pages", { keyPrefix: "login" });

  useEffect(() => {
    if (authState === AuthState.Reauthentication) setValue({ mail: username });
    if (authState === AuthState.Unauthenticated)
      setValue({ mail: undefined, code: undefined });
  }, [authState, setValue, username]);

  useEffect(() => {
    console.log("VALUE MAIL: ", value.mail, " | CODE: ", value.code);
  }, [value]);

  useEffect(() => {
    console.log("AUTHSTATE", authState);
  }, [authState]);

  const submit = () => {
    console.log("in submit", authState);
    if (
      authState == AuthState.Unauthenticated ||
      authState === AuthState.Reauthentication
    ) {
      if (value.mail) {
        setLoading(true);
        submitUsername(value.mail, () => setLoading(false));
      }
    } else {
      if (value.code && value.mail) {
        setLoading(true);
        submitCode(value.mail, value.code, () => setLoading(false));
      }
    }
  };

  return (
    <Page id="login-page">
      <CookieMessage
        disabled={authState === AuthState.Reauthentication}
        hasAgreed={() => setCookiesOk(true)}
      />
      <IonSegment
        hidden={true}
        onIonChange={({ detail: { value } }) => i18n.changeLanguage(value)}
        value={
          i18n.language.includes("-")
            ? i18n.language.split("-")[0]
            : i18n.language
        }
      >
        <IonSegmentButton value="en">English</IonSegmentButton>
        <IonSegmentButton value="de">Deutsch</IonSegmentButton>
      </IonSegment>

      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        {!hasAgreedToAgb && (
          <>
            <TermsContainer>
              <Checkbox
                type="checkbox"
                checked={checkboxChecked}
                onClick={toggleCheckbox}
                disabled={!cookiesOk}
              />
              <div>
                Hiermit stimme ich der <a>Datenverarbeitung</a> und den{" "}
                <a>Allgemeinen Geschäftsbedingungen</a> zu.
              </div>
            </TermsContainer>
            <ButtonContainer style={{ marginTop: 30 }}>
              <Button
                onClick={() => {
                  setHasAgreedToAgb(true);
                }}
                disabled={!checkboxChecked || !cookiesOk}
              >
                Fortfahren
              </Button>
            </ButtonContainer>
          </>
        )}
        {hasAgreedToAgb && (
          <MailInput
            loading={authState !== AuthState.CodeRequired && loading}
            value={value.mail}
            setValue={(val) =>
              setValue((cur) => ({
                ...cur,
                mail: val,
              }))
            }
            onEnterPressed={submit}
            submitButtonHidden={authState === AuthState.CodeRequired}
            cancelButtonHidden={authState !== AuthState.CodeRequired}
            onCancel={logout}
          />
        )}

        {authState === AuthState.CodeRequired && hasAgreedToAgb && (
          <CodeInput
            loading={loading}
            value={value.code}
            setValue={(val) =>
              setValue((cur) => ({
                ...cur,
                code: val,
              }))
            }
            onEnterPressed={submit}
            onResend={() => submitUsername(value.mail!)}
          />
        )}
      </Container>
    </Page>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: ({ user }) => user,
  mapDispatchToProps: {
    submitUsername,
    submitCode,
    logout,
  },
  component: LoginPage,
});

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
`;

export const Button = styled.button`
  padding: 10px 22px;
  border-radius: 5px;
  background: #0e5e6f;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  max-width: 200px;
  &:disabled {
    background: #acb6bf;
    cursor: disabled;
  }
  &:hover:enabled {
    opacity: 80%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const Container = styled.div`
  width: 500px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 30px;
  & a {
    color: #0a95ad;
    font-weight: 600;
  }
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  margin: 0 auto;
  display: block;
  border: 1px solid #77828c;
  display: inline-block;
  vertical-align: middle;
  background-color: ${(props) => (props.checked ? "#0A95AD" : "transparent")};
  &:checked {
    background-color: green;
  }
`;

const Page = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;
