import { systemReducer } from './system/system.reducer'
import { userReducer } from './user/user.reducer'
import { AuthState, TokenLocation } from './user/user.state'
import { combineReducers } from './util/combineReducers'

export var initialState: AppState = {
    user: {
        authState: AuthState.Loading,
        tokenLocation: TokenLocation.STORAGE,
    },
    system: {
        alerts: [],
        toasts: [],
    },
}

export const reducers = combineReducers({
    user: userReducer,
    system: systemReducer,
})

export type AppState = ReturnType<typeof reducers>
