import {Storage} from "@capacitor/storage";
import jwt_decode from "jwt-decode";
import {Dispatch} from "react";
import ColorVariables from "../../helpers/ColorVariables";
import {CustomerData} from "../models/customer-data";
import GlobalAlerts from "../models/global.alerts";
import GlobalToasts from "../models/global.toasts";
import {ResponseMessage} from "../models/ResponseMessage";
import {SystemError} from "../models/system-error";
import {presentGlobalAlert, presentGlobalToast, setSystemError} from "../system/system.actions";
import {getQueryParam} from "../util/queryparams";
import {ActionType} from "../util/types";
import {default as userApi, default as UserApi} from "./user.api";
import {AuthState, TokenLocation} from "./user.state";

export const loadCustomerData =
  ({force}: {force: boolean} = {force: false}) =>
  async (dispatch: React.Dispatch<any>) => {
    if (force) await UserApi.removeCustomerData();
    try {
      const customerData: ResponseMessage | CustomerData = await UserApi.getCustomerDataApi();
      if (customerData instanceof ResponseMessage) {
        dispatch(presentGlobalAlert(GlobalAlerts.loadCustomerDataError()));
        await UserApi.removeCustomerData();
      } else {
        dispatch(setCustomerData(customerData));
        Object.entries(customerData.colors).map(([key, value]) => ColorVariables.setCssVariable(key, value));
        if (customerData.name.toUpperCase() == "STANDARD")
          dispatch(presentGlobalAlert(GlobalAlerts.loadCustomerDataStandard()));
      }
    } catch {
      dispatch(presentGlobalAlert(GlobalAlerts.loadCustomerDataError()));
      await userApi.removeCustomerData();
    }
  };

export const submitUsername =
  (mail: string, callback: (successfull: boolean) => any = () => null) =>
  async (dispatch: React.Dispatch<any>) => {
    //dispatch(setAuthState(AuthState.Unauthenticated))
    const mailSent = await UserApi.submitMail(mail);
    if (mailSent?.message === "mail sent") {
      callback(true);
      dispatch(presentGlobalAlert(GlobalAlerts.submitUsernameSuccess(mail)));
      dispatch(setAuthState(AuthState.CodeRequired));
    } else {
      dispatch(presentGlobalAlert(GlobalAlerts.submitUsernameError()));
      return callback(false);
    }
  };

export const submitCode =
  (mail: string, code: string, callback: (successfull: boolean) => any = () => null) =>
  async (dispatch: React.Dispatch<any>) => {
    const sub = await UserApi.submitCode(mail, code);
    if (sub instanceof ResponseMessage) {
      dispatch(presentGlobalAlert(GlobalAlerts.submitCodeError()));
      return callback(false);
    }
    await loadCustomerData()(dispatch);
    callback(true);
    dispatch(setUsername(sub));
    dispatch(setAuthState(AuthState.Authenticated));
    dispatch(presentGlobalToast(GlobalToasts.userLoggedIn({email: sub})));
  };

export const logout =
  (
    {canReauthenticate}: {canReauthenticate: boolean} = {
      canReauthenticate: false,
    }
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    await Storage.clear();
    ColorVariables.resetSetCssVariables();
    dispatch(setCustomerData(null));
    if (canReauthenticate) return;
    dispatch(setAuthState(AuthState.Unauthenticated));
    dispatch(presentGlobalAlert(GlobalAlerts.userLoggedOut()));
  };

const getCorrectToken = async (dispatch: Dispatch<any>): Promise<{token: string; loc: TokenLocation}> => {
  const queryToken = getQueryParam("access_token");
  if (queryToken) {
    dispatch(setTokenLocation(TokenLocation.TEMP));
    return {token: queryToken, loc: TokenLocation.TEMP};
  }
  dispatch(setTokenLocation(TokenLocation.STORAGE));
  return {
    token: (
      await Storage.get({
        key: "token",
      })
    ).value as any,
    loc: TokenLocation.STORAGE,
  };
};

export const loadUserFromStorage = () => async (dispatch: React.Dispatch<any>) => {
  const {token: access_token, loc} = await getCorrectToken(dispatch);
  //console.log('ACCESS_TOKEN', access_token)
  if (!access_token) return dispatch(setAuthState(AuthState.Unauthenticated));
  try {
    const {sub, exp} = jwt_decode(access_token) as any;
    dispatch(setUsername(sub));
    if (exp * 1000 <= new Date().getTime()) {
      if (loc === TokenLocation.TEMP) {
        console.log("Code is expired and location is TEMP");
        return dispatch(setSystemError(SystemError.QUERY_PARAM_TOKEN_ERROR));
      }
      dispatch(presentGlobalAlert(GlobalAlerts.userMustReAuthenticate()));
      return dispatch(setAuthState(AuthState.Reauthentication));
      //return await logout({ canReauthenticate: true })(dispatch)
    }
    await loadCustomerData({force: loc === TokenLocation.TEMP})(dispatch);
    // dispatch(presentGlobalToast(GlobalToasts.userLoggedIn({email: sub})));
    return dispatch(setAuthState(AuthState.Authenticated));
  } catch (e) {
    console.error("ERROR LOADING USER", e);
    logout()(dispatch);
  }
};

export const setUsername = (username: string) => {
  return {
    type: "set-username",
    username,
  } as const;
};

export const setAuthState = (state: AuthState) => {
  return {
    type: "set-authstate",
    state,
  } as const;
};

const setCustomerData = (data: CustomerData | null) => {
  return {
    type: "set-customer-data",
    data,
  } as const;
};

const setTokenLocation = (tokenLocation: TokenLocation) => {
  return {
    type: "set-token-location",
    tokenLocation,
  } as const;
};

export type UserActions =
  | ActionType<typeof setUsername>
  | ActionType<typeof setAuthState>
  | ActionType<typeof setCustomerData>
  | ActionType<typeof setTokenLocation>;
