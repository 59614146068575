import { getQueryParam } from '../data/util/queryparams'
import ColorConverter from './ColorConverter'

var setVariables: Record<string, string> = {}

const setCssVariable = (name: string, hexValue?: string) => {
    hexValue = getQueryParam(name) || hexValue
    //console.log('query param', hexValue)
    if (!hexValue) return
    //document.documentElement.style.setProperty("--subTitleLeftMargin", "6vw");

    /*
	--ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
	*/

    const shades = ColorConverter.generateShades(hexValue, 8)

    const contrast_rgb = ColorConverter.generateTextColor(hexValue)

    ;[
        {
            name: '--ion-color-' + name,
            value: hexValue,
        },
        {
            name: '--ion-color-' + name + '-rgb',
            value: ColorConverter.hexToRgb(hexValue).join(', '),
        },
        {
            name: '--ion-color-' + name + '-contrast',
            value: ColorConverter.rgbToHex(contrast_rgb),
        },
        {
            name: '--ion-color-' + name + '-contrast-rgb',
            value: contrast_rgb.join(', '),
        },
        {
            name: '--ion-color-' + name + '-shade',
            value: shades[1],
        },
        {
            name: '--ion-color-' + name + '-tint',
            value: shades[7],
        },
    ].forEach(({ name, value }) => {
        //if (name.includes('success')) console.log(name, value)
        try {
            const oldVal = getComputedStyle(
                document.documentElement,
            ).getPropertyValue(name)
            setVariables[name] = oldVal
            document.documentElement.style.setProperty(
                name,
                getQueryParam(name) || value,
            )
            //console.info('SETTING PROPERTY: ' + name + ' ' + value)
        } catch (e) {
            console.error(e)
        }
    })
}

const resetSetCssVariables = () => {
    Object.entries(setVariables).forEach(([name, value]) => {
        document.documentElement.style.setProperty(name, value)
    })
    setVariables = {}
}

export default { setCssVariable, resetSetCssVariables }
