import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonSpinner,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  closeCircleOutline,
  mailOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { AuthState } from "../../../data/user/user.state";
import { connect } from "../../../data/util/connect";
import styled from "styled-components";
import { Button, ButtonContainer } from "../Login";

interface OwnProps {
  value?: string;
  setValue: (value: string) => any;
  onEnterPressed?: () => any;
  submitButtonHidden?: boolean;
  onCancel?: () => any;
  cancelButtonHidden?: boolean;
  loading?: boolean;
}
interface StateProps {
  authState: AuthState;
}

const MailInput: React.FC<OwnProps & StateProps> = ({
  value = "",
  setValue,
  authState,
  onEnterPressed = () => null,
  submitButtonHidden,
  onCancel = () => null,
  cancelButtonHidden,
  loading = false,
}) => {
  const { t } = useTranslation("pages", { keyPrefix: "login" });
  return (
    <div>
      <span>E-Mail Adresse</span>
      <InputWrapper>
        <IonItem
          lines="none"
          style={{
            borderRadius: 10,
            margin: submitButtonHidden ? 0 : 0,
          }}
          color={submitButtonHidden ? "light" : ""}
        >
          <IonIcon slot="start" size="small" icon={mailOutline} />
          <IonInput
            placeholder={t("emailInputPlaceholder")}
            disabled={authState === AuthState.CodeRequired || loading}
            value={value}
            onIonChange={(e) => setValue(e.detail.value as string)}
            type="email"
            onKeyDown={({ key }) => {
              if (key.toLowerCase().includes("enter")) onEnterPressed();
            }}
          />
          <IonButtons
            hidden={submitButtonHidden && cancelButtonHidden}
            slot="end"
          >
            <IonButton
              hidden={submitButtonHidden || loading}
              disabled={!value || authState === AuthState.Loading}
              onClick={onEnterPressed}
            >
              <IonIcon slot="icon-only" icon={arrowForwardCircleOutline} />
            </IonButton>
            <IonButton
              hidden={cancelButtonHidden || loading}
              disabled={!value || authState === AuthState.Loading}
              onClick={onCancel}
            >
              <IonIcon
                slot="icon-only"
                icon={closeCircleOutline}
                color="danger"
              />
            </IonButton>
          </IonButtons>
          {loading && <IonSpinner slot="end" />}
        </IonItem>
      </InputWrapper>
      <ButtonContainer>
        <Button
          hidden={submitButtonHidden || loading}
          disabled={!value || authState === AuthState.Loading}
          onClick={onEnterPressed}
          style={{ marginTop: 30 }}
        >
          Weiter
        </Button>
      </ButtonContainer>
    </div>
  );
};

export const InputWrapper = styled.div`
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid var(--grey-grey-300, #acb6bf);
  display: flex;
  flex-direction: column;
`;

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    authState: state.user.authState,
  }),
  component: MailInput,
});
