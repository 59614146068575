import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonText,
    IonToggle,
} from '@ionic/react'
import React from 'react'
import './ExpertToggle.scss'

interface OwnProps {
    checked: boolean
    onChange: (val: boolean) => any
}

const ExpertToggle: React.FC<OwnProps> = ({ checked, onChange }) => {
    return (
        <IonCard>
            <IonCardContent>
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol size="10">
                            <IonLabel
                                className="expert-toggle-title"
                                color="dark"
                            >
                                Expertenmodus
                            </IonLabel>
                            <br />
                            <IonText style={{ fontSize: 14 }}>
                                Im Expertenmodus stehen Dir weitere
                                Eingabemöglichkeiten zur Verfügung
                            </IonText>
                        </IonCol>
                        <IonCol size="2" className="ion-text-center">
                            <IonToggle
                                checked={checked}
                                onIonChange={(e) => onChange(e.detail.checked)}
                                color="success"
                                className="expert-toggle-content"
                            ></IonToggle>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default ExpertToggle
