import { BenefitRequestDto } from '../models/benefit'
import { LofinoDataTypes } from '../models/lofino-data-types'
import { LofinoInputBlock } from '../models/lofino-input-block'

interface FormI {
    [key: number]: number | string
}

const parseValue = (type: LofinoDataTypes, value: string | number) => {
    switch (type) {
        case LofinoDataTypes.float:
            return typeof value === 'string'
                ? parseFloat(value.split(',').join('.'))
                : value
        case LofinoDataTypes.string:
            return value.toString()
        default:
            return value
    }
}

const findFormInformationObj = (
    formInformation: LofinoInputBlock[],
    key: string,
) => {
    for (const info of formInformation) {
        for (const field of info.inputs) {
            if (field.valueKey.toString() === key) return field
        }
    }
    return {} as any
}

const parseFormResponse = (
    form: FormI,
    formInformation: LofinoInputBlock[],
): BenefitRequestDto =>
    Object.entries(form).map(([key, value]) => {
        const { type } = findFormInformationObj(formInformation, key)
        return {
            valueKey: parseInt(key),
            value: parseValue(type, value),
        }
    })

export default { parseFormResponse, parseValue }
