import { Dispatch } from 'react'
import { StateContext } from '../AppContext'
import { Benefit } from '../models/benefit'
import { LofinoInputBlock } from '../models/lofino-input-block'
import { ResponseMessage } from '../models/ResponseMessage'
import { SystemError } from '../models/system-error'
import { setSystemError } from '../system/system.actions'
import { allQueryParamsUsed } from '../util/queryparams'
import { ActionType } from '../util/types'
import formularApi from './formular.api'
import { FormularStateEnum } from './formular.state'

export const loadFields = () => async (dispatch: Dispatch<any>) => {
    dispatch(setFormularState(FormularStateEnum.LoadingFields))
    dispatch(clearError())
    try {
        const blocks = await formularApi.GET_FIELDS()
        if (Array.isArray(blocks)) {
            dispatch(setLofinoInputBlocks(blocks))
            dispatch(setFormularState(FormularStateEnum.Input))
            if (allQueryParamsUsed() !== true)
                StateContext.dispatch(
                    setSystemError(SystemError.QUERY_PARAM_ERROR),
                )
            return
        }
        if (blocks instanceof ResponseMessage) {
            dispatch(setError(blocks))
            dispatch(setFormularState(FormularStateEnum.FieldError))
            return
        }
        dispatchError(
            'Fehler beim Laden der Daten. Versuche es später erneut!',
        )(dispatch)
        return
    } catch {
        dispatchError(
            'Fehler beim Laden der Daten. Versuche es später erneut!',
        )(dispatch)
    }
}

const dispatchError = (msg: string) => (dispatch: Dispatch<any>) => {
    dispatch(
        setError(
            new ResponseMessage({
                headers: null!,
                text: msg,
            }),
        ),
    )
    dispatch(setFormularState(FormularStateEnum.FieldError))
}

export const calculateBenefit =
    (
        content: {
            valueKey: number
            value: any
        }[],
    ) =>
    async (dispatch: Dispatch<any>) => {
        dispatch(setFormularState(FormularStateEnum.LoadingBenefit))
        dispatch(clearError())
        const benefit = await formularApi.CALCULATE(content)
        if (Array.isArray(benefit)) {
            console.log('correct response all went good')
            dispatch(setBenefit(benefit))
            dispatch(setFormularState(FormularStateEnum.Output))
        } else {
            console.log(
                'in calc benfit setting error and formular state to error',
            )
            dispatch(setError(benefit))
            dispatch(setFormularState(FormularStateEnum.BenefitError))
        }
    }

const setLofinoInputBlocks = (blocks: LofinoInputBlock[]) =>
    ({
        type: 'set-formular-block',
        blocks,
    } as const)

const setBenefit = (benefit: Benefit) =>
    ({
        type: 'set-formular-benefit',
        benefit,
    } as const)

const setFormularState = (state: FormularStateEnum) =>
    ({
        type: 'set-formular-state',
        state,
    } as const)

const setError = (error: ResponseMessage) =>
    ({
        type: 'set-formular-api-error',
        error,
    } as const)

const clearError = () =>
    ({
        type: 'clear-formular-api-error',
    } as const)

export const clearBenefit = () =>
    ({
        type: 'clear-formular-benefit',
    } as const)

export type FormularActions =
    | ActionType<typeof setBenefit>
    | ActionType<typeof setLofinoInputBlocks>
    | ActionType<typeof clearBenefit>
    | ActionType<typeof setFormularState>
    | ActionType<typeof setError>
    | ActionType<typeof clearError>
