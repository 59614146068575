import { FormularActions } from './formular.actions'
import FormularState from './formular.state'

function formularReducer(
    state: FormularState,
    action: FormularActions,
): FormularState {
    switch (action.type) {
        case 'clear-formular-api-error':
            return { ...state, error: undefined }
        case 'clear-formular-benefit':
            return { ...state, benefit: undefined }
        case 'set-formular-api-error':
            return { ...state, error: action.error }
        case 'set-formular-benefit':
            return { ...state, benefit: action.benefit }
        case 'set-formular-block':
            return { ...state, blocks: action.blocks }
        case 'set-formular-state':
            return { ...state, formularState: action.state }
    }
}

export default formularReducer
