import i18next from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

i18next
    .use(
        resourcesToBackend((language, namespace, callback) => {
            //console.log(language)
            import(`../lang/${language}/${namespace}.json`)
                .then((resources) => {
                    callback(null, resources)
                })
                .catch((error) => {
                    callback(error, null)
                })
        }),
    )
    //.use(Backend)
    //.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        /* resources: {
        en: {
          translation: {
            loginInfo: "Welcome to our app!",
            loginPasswordError: "Enter your password",
          },
        },
        de: {
          translation: {
            loginInfo:
              "Willkommen zu unserer Vorteilsrechner App. Loggen Sie sich mit Ihrer Firmenaddresse ein um Ihre Vorteile zu berechnen!",
            loginPasswordError: "Bitte geben Sie Ihe Passwort ein.",
          },
        },
      }, */
        // if you're using a language detector, do not define the lng option
        fallbackLng: 'de',
        ns: ['common', 'pages', 'responses'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        /*  backend: {
            loadPath: '../lang/{{lng}}/{{ns}}.json', //'/locales/{{lng}}/{{ns}}.json'
        }, */
        react: {
            useSuspense: false,
        },
    })

//console.log(navigator.language)
