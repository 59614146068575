import { Storage } from '@capacitor/storage'
import jwtDecode from 'jwt-decode'
import * as yup from 'yup'
import { CustomerData } from '../models/customer-data'
import { ResponseMessage } from '../models/ResponseMessage'
import api from '../util/api'

const submitMail = async (mail: string) => {
    try {
        const res: { mail: string } | any = await api.PUT<string>('/mail', {
            anonymus: true,
            body: { mail },
            validationSchema: yup.object().shape({
                message: yup
                    .string()
                    .required()
                    .test(
                        'match',
                        'response did not match as expected',
                        (s) => s === 'mail sent',
                    ),
            }),
        })
        if (res.message !== 'mail sent')
            throw new ResponseMessage({
                headers: null!,
                text: "Couldn't send the email",
            })
        return res
    } catch (e) {
        if (e instanceof ResponseMessage) return new ResponseMessage(e)
        return new ResponseMessage({
            headers: null!,
            text: 'Error when requesting the code',
        })
    }
}

const submitCode = async (mail: string, code: string) => {
    try {
        const res = await api.POST<{ token: string }>('/code', {
            anonymus: true,
            body: { mail, code },
            validationSchema: yup
                .object()
                .shape({
                    token: yup.string().required(),
                })
                .required(),
        })
        await Storage.set({
            key: 'token',
            value: res.token,
        })
        const { sub } = jwtDecode(res.token) as any
        return sub
    } catch (e) {
        if (e instanceof ResponseMessage) return new ResponseMessage(e)
        return new ResponseMessage({
            headers: null!,
            text: 'Error when submitting the code',
        })
    }
}

const getCustomerDataApi = async () => {
    try {
        const { value } = await Storage.get({ key: 'customer-data' })
        console.log('SOTRED VALUE', value ? JSON.parse(value) : {})
        if (value) return JSON.parse(value)
        const customerData = await api.GET<CustomerData>('/customer-data', {
            validationSchema: yup
                .object()
                .shape({
                    name: yup.string().required(),
                    logo: yup.string().required(),
                    website: yup.string().required(),
                    colors: yup
                        .object()
                        .shape({
                            primary: yup.string().optional(),
                            secondary: yup.string().optional().nullable(),
                            tertiary: yup.string().optional().nullable(),
                            qartiary: yup.string().optional().nullable(),
                            quntiary: yup.string().optional().nullable(),
                            sextiary: yup.string().optional().nullable(),
                            success: yup.string().optional().nullable(),
                            warning: yup.string().optional().nullable(),
                            danger: yup.string().optional().nullable(),
                        })
                        .required(),
                })
                .required(),
        })
        if (customerData)
            await Storage.set({
                key: 'customer-data',
                value: JSON.stringify(customerData),
            })
        console.log('RETRIEVED CUSTOMER DATA', customerData)
        return customerData
    } catch (e) {
        if (e instanceof ResponseMessage) return new ResponseMessage(e)
        return new ResponseMessage({
            headers: null!,
            text: `Couldn't load customer data.`,
        })
    }
}

const removeCustomerData = () => Storage.remove({ key: 'customer-data' })

export default {
    submitMail,
    submitCode,
    getCustomerDataApi,
    removeCustomerData,
}
