import React from 'react'
import { presentGlobalToast } from '../data/system/system.actions'
import { connect } from '../data/util/connect'

interface OwnProps {
    fallbackUi?: React.Component
}

interface DispatchProps {
    presentGlobalToast: typeof presentGlobalToast
}

class ErrorBoundary extends React.Component<
    OwnProps & DispatchProps,
    any,
    { hasError: boolean }
> {
    constructor(props: OwnProps & DispatchProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo)
        this.props.presentGlobalToast({
            header: "Something wen't wrong",
            message:
                "We're sorry but something went wrong internally. You cannot use the just triggered action.",
            color: 'danger',
        })
    }

    render() {
        /* if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallbackUi || <ErrorFallbackUI />
        } */

        return this.props.children
    }
}

export default connect<OwnProps, {}, DispatchProps>({
    component: ErrorBoundary,
    mapDispatchToProps: {
        presentGlobalToast,
    },
})
