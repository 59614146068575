import React from 'react'

const SpaceOutput: React.FC = () => (
    <div
        style={{
            height: '1px',
            backgroundColor: 'transparent',
        }}
    />
)

export default SpaceOutput
