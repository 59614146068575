import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { openOutline } from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerData } from "../../data/models/customer-data";
import { logout } from "../../data/user/user.actions";
import { connect } from "../../data/util/connect";
import useSearchParam from "../../hooks/useSearchParam";
import Licenses from "../licenses/Licenses";
import "./styles/Profile.scss";

interface StateProps {
  username?: string;
  customerData?: CustomerData;
}
interface DispatchProps {
  logout: typeof logout;
}

const ProfilePage: React.FC<StateProps & DispatchProps> = ({
  logout,
  username,
  customerData,
}) => {
  const searchParamLicensesOpen = useSearchParam("licensesOpen");
  const [licensesOpen, setLicensesOpen] = useState(
    searchParamLicensesOpen == "true"
  );
  const { t } = useTranslation("pages", { keyPrefix: "profile" });
  const [presentAlert] = useIonAlert();
  const handleLogout = () => {
    presentAlert({
      header: t("logoutCheck"),
      buttons: [
        {
          text: t("logout"),
          role: "submit",
          handler: () => logout(),
        },
        { text: t("logoutCancel"), role: "cancel" },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text={t("backButtonText")}
              defaultHref="/collection"
            />
          </IonButtons>
          <IonTitle>{t("title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            margin: "auto",
            maxWidth: 700,
          }}
        >
          <IonCard>
            <IonCardContent>
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="3">
                    <IonLabel className="profile-card-title" color="dark">
                      {t("user")}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="9" className="ion-text-right">
                    <IonLabel className="profile-card-content" color="medium">
                      {username || "Unbekannter Nutzer"}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          {customerData &&
            customerData.logo &&
            customerData.website &&
            customerData.website && (
              <IonCard
                href={
                  customerData.website.includes("http")
                    ? customerData.website
                    : "https://" + customerData.website
                }
                target="_blank"
                button
                mode="ios"
              >
                <IonCardContent>
                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      <IonCol size="9">
                        <IonLabel
                          className="profile-card-title profile-card-content"
                          color="dark"
                        >
                          {customerData.name}
                        </IonLabel>
                        <br />
                        <IonLabel className="profile-card-content">
                          {customerData.website}
                        </IonLabel>
                        <IonIcon
                          style={{ marginLeft: 5 }}
                          className="profile-card-content"
                          icon={openOutline}
                        />
                      </IonCol>
                      <IonCol size="3" className="ion-text-right">
                        <img
                          className="profile-card-content"
                          style={{ height: 50 }}
                          alt="logo"
                          src={
                            customerData.logo.includes("data:image")
                              ? customerData.logo
                              : "data:image/svg+xml;base64," + customerData.logo
                          }
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            )}
          <IonCard hidden>
            <IonCardContent>
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="10">
                    <IonLabel className="profile-card-title" color="dark">
                      {t("saveHistoryOnDevice")}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="2" className="ion-text-right">
                    <IonToggle
                      color="success"
                      className="profile-card-content"
                    ></IonToggle>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard button onClick={() => setLicensesOpen(true)}>
            <IonCardContent>
              <IonLabel className="profile-card-title" color="dark">
                Lizenzen
              </IonLabel>
            </IonCardContent>
          </IonCard>
          <IonCard button onClick={handleLogout}>
            <IonCardContent>
              <IonLabel className="profile-card-logout-title" color="danger">
                {t("logout")}
              </IonLabel>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonModal
        isOpen={licensesOpen}
        backdropDismiss={true}
        onDidDismiss={() => setLicensesOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Lizenzen</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setLicensesOpen(false)}>
                Schließen
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <Licenses />
      </IonModal>
    </IonPage>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    username: state.user.username,
    customerData: state.user.customerData!,
  }),
  mapDispatchToProps: {
    logout,
  },
  component: ProfilePage,
});

/*
OLD LIST
<IonList hidden>
          <IonItemDivider>{t("userData")}</IonItemDivider>
          <IonItem>
            <IonLabel>{t("user")}</IonLabel>
            <IonLabel slot="end">{username || "Unbekannter Nutzer"}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>{t("saveHistoryOnDevice")}</IonLabel>
            <IonToggle></IonToggle>
          </IonItem>
          <IonItem hidden>
            <IonLabel>{t("language")}</IonLabel>
            <IonSelect
              onIonChange={({ detail: { value } }) =>
                i18n.changeLanguage(value)
              }
              value={
                i18n.language.includes("-")
                  ? i18n.language.split("-")[0]
                  : i18n.language
              }
            >
              {[
                { label: "English", id: "en" },
                { label: "Deutsch", id: "de" },
              ].map((lng) => {
                return (
                  <IonSelectOption key={"lang-option-" + lng.id} value={lng.id}>
                    {lng.label}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem button onClick={handleLogout}>
            <IonLabel color="danger" style={{ fontWeight: "bold" }}>
              {t("logout")}
            </IonLabel>
          </IonItem>
        </IonList>
*/
