import {
  AlertOptions,
  ToastOptions,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../data/AppContext";
import "./GlobalMessages.scss";
import { removeGlobalMessage } from "../data/system/system.actions";

interface OwnProps {
  logging?: boolean;
}

const GlobalMessages: React.FC<OwnProps> = ({ logging = false }) => {
  const {
    dispatch,
    state: {
      system: { alerts, toasts },
    },
  } = useContext(AppContext);

  const [alertPresenting, setAlertPresenting] = useState(false);
  const [toastPresenting, setToastPresenting] = useState(false);

  const [presentIonAlert] = useIonAlert();
  const [presentIonToast] = useIonToast();

  const presentAlert = useCallback(
    (alert: AlertOptions & HookOverlayOptions) => {
      if (alertPresenting) return;
      if (logging) console.log("presenting alert", alert);
      setAlertPresenting(true);
      presentIonAlert({
        ...alert,
        onDidDismiss: (ev) => {
          dispatch(removeGlobalMessage("alert"));
          setAlertPresenting(false);
          if (alert.onDidDismiss) alert.onDidDismiss(ev);
        },
      });
    },
    [alertPresenting, logging, setAlertPresenting, presentIonAlert, dispatch]
  );

  const presentToast = useCallback(
    (toast: ToastOptions & HookOverlayOptions) => {
      if (toastPresenting) return;
      if (logging) console.log("presenting toast", toast);
      setToastPresenting(true);
      presentIonToast({
        position: "top",
        color: "medium",
        duration: 2000,
        ...toast,
        cssClass: (toast.cssClass || "") + " global-toast",
        onDidDismiss: (ev) => {
          dispatch(removeGlobalMessage("toast"));
          setToastPresenting(false);
          if (toast.onDidDismiss) toast.onDidDismiss(ev);
        },
      });
    },
    [toastPresenting, logging, setToastPresenting, presentIonToast, dispatch]
  );

  useEffect(() => {
    if (logging) console.log(alerts);
    if (!alertPresenting && alerts[0]) presentAlert(alerts[0]);
  }, [alerts.length, alertPresenting, logging, alerts, presentAlert]);

  useEffect(() => {
    if (logging) console.log(toasts);
    if (!toastPresenting && toasts[0]) presentToast(toasts[0]);
  }, [toasts.length, toastPresenting, logging, toasts, presentToast]);

  return null;
};

export default GlobalMessages;
