import { IonCol, IonIcon, useIonAlert } from '@ionic/react'
import { helpOutline } from 'ionicons/icons'
import React, { useMemo } from 'react'

interface ColProps {
    size?: string
    sizeLg?: string
}

interface OwnProps {
    label: string
    info?: string
    responsive?: boolean | [labelCol: ColProps, infoCol: ColProps]
    alertHeader?: string
    valueKey?: string | number
    style?: any
}

const Label: React.FC<OwnProps> = ({
    label,
    info,
    responsive = true,
    alertHeader = 'Hilfe',
    valueKey,
    style,
}) => {
    const [presentAlert] = useIonAlert()
    const [labelParam, infoParam] = useMemo(() => {
        if (Array.isArray(responsive)) return responsive
        if (responsive)
            return [
                { size: '10', sizeLg: '5' },
                { size: '2', sizeLg: '1' },
            ]
        return [{ size: '5' }, { size: '1' }]
    }, [responsive])
    return (
        <>
            <IonCol {...labelParam} style={{ margin: 'auto' }}>
                <div
                    hidden
                    style={{
                        position: 'absolute',
                        top: -8,
                        left: 0,
                        fontSize: 9,
                    }}
                >
                    {valueKey}
                </div>
                <div
                    className="data-input-label"
                    style={{
                        ...style,
                        verticalAlign: 'middle',
                    }}
                >
                    {label}
                </div>
            </IonCol>
            <IonCol
                {...infoParam}
                className="ion-text-center"
                style={{ margin: 'auto' }}
            >
                <div
                    style={{
                        height: '100%',
                    }}
                >
                    <IonIcon
                        hidden={!info}
                        onClick={() =>
                            presentAlert({
                                header: alertHeader,
                                message: info,
                                buttons: [{ text: 'Okay', role: 'submit' }],
                            })
                        }
                        color="primary"
                        className="data-input-help-icon"
                        icon={helpOutline}
                    />
                </div>
            </IonCol>
        </>
    )
}

export default Label
