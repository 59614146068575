import { IonCard, IonCardContent, IonGrid, IonRow } from "@ionic/react";
import randomColor from "randomcolor";
import React, { useMemo } from "react";
import { LofinoDataTypes } from "../../../data/models/lofino-data-types";
import { LofinoInputBlock } from "../../../data/models/lofino-input-block";
import { getQueryParam } from "../../../data/util/queryparams";
import Header from "./default/Header";
import Info from "./default/Info";
import DateInput from "./input/Date.input";
import SelectionInput from "./input/Selection.input";
import ValueInput from "./input/Value.input";
import { LofinoInputField } from "../../../data/models/lofino-input-field";

interface OwnProps {
  isFirst?: boolean;
  isLast?: boolean;
  expert?: boolean;
  color?: string;
  block: LofinoInputBlock;
}

const Block: React.FC<OwnProps> = ({
  block,
  expert,
  isFirst = false,
  isLast = false,
  color,
}) => {
  const [inputs] = useMemo(
    () => [
      block.inputs.sort(
        ({ valueKey: valueKeyA }, { valueKey: valueKeyB }) =>
          valueKeyA - valueKeyB
      ),
      randomColor(),
    ],
    [block]
  );
  return (
    <IonCard className="ion-no-padding">
      <div style={{ height: 5, background: color || undefined }} />
      <IonCardContent className="ion-no-padding">
        <IonGrid className="ion-no-padding" style={{ marginLeft: 5 }}>
          <IonRow style={{ height: 10 }} />

          <Header label={block.label} />

          {block !== null && !!block && block.label !== "Basisdaten" && (
            <p style={{ paddingLeft: 5 }}>
              Dieses Feld bezieht sich auf{' "'}
              <span style={{ fontWeight: 500 }}>{block.label}</span>
              {'"'}. Alle Beziehungsfelder zusammen dürfen in Summe nicht mehr
              ergeben als auf das beziehende Feld (
              <span style={{ fontWeight: 500 }}>{block.label}</span>
              ).
            </p>
          )}
          {inputs.map((inputField, index) => {
            const queryParam = getQueryParam(inputField.label);
            if (queryParam) inputField.defaultValue = queryParam;
            const isLastInBlock = index + 1 === inputs.length;
            if (inputField.expert && !expert) return null;
            if (
              inputField.validValues &&
              Array.isArray(inputField.validValues)
            ) {
              // used for rendering the Steuerklasse
              return (
                <SelectionInput
                  key={"selection-" + inputField.valueKey}
                  {...inputField}
                  lines={isLastInBlock ? "none" : undefined}
                  lofinoDataType={inputField.type}
                  //disabled={!!queryParam}
                />
              );
            }
            return renderLabelsAndInputs(inputField, isLastInBlock);
          })}
          {isLast === false && <IonRow style={{ height: 10 }} />}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

const renderLabelsAndInputs = (
  inputField: LofinoInputField,
  isLastInBlock: boolean
) => {
  switch (inputField.type) {
    case LofinoDataTypes.date:
      return (
        <DateInput
          key={"input-" + inputField.valueKey}
          lines={isLastInBlock ? "none" : undefined}
          //disabled={!!queryParam}
          {...inputField}
        />
      );
    case LofinoDataTypes.text:
      return <Info key={"input-" + inputField.valueKey} {...inputField} />;
    case LofinoDataTypes.space:
      return (
        <IonRow key={"space-" + inputField.valueKey} style={{ height: 5 }} />
      );
    case LofinoDataTypes.header:
      return <Header key={"header-" + inputField.valueKey} {...inputField} />;
    default:
      return (
        <ValueInput
          key={"input-" + inputField.valueKey}
          lines={isLastInBlock ? "none" : undefined}
          {...inputField}
          //disabled={!!queryParam}
        />
      );
  }
};

export default Block;
