import React, { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";
import { AppState } from "../state";
import { ContextType, DispatchObject } from "./types";

interface ConnectParams<TOwnProps, TStateProps, TDispatchProps, TState> {
  mapStateToProps?: (state: TState, props: TOwnProps) => TStateProps;
  mapDispatchToProps?: TDispatchProps;
  component: React.ComponentType<any>;
  Context?: React.Context<ContextType<TState>>;
}

export function connect<
  TOwnProps = any,
  TStateProps = any,
  TDispatchProps = any,
  TState = AppState
>({
  mapStateToProps = () => ({} as TStateProps),
  mapDispatchToProps = {} as TDispatchProps,
  component,
  Context,
}: ConnectParams<
  TOwnProps,
  TStateProps,
  TDispatchProps,
  TState
>): React.FunctionComponent<TOwnProps> {
  const Connect = (ownProps: TOwnProps) => {
    const _Context = useMemo(() => {
      if (Context) return Context;
      return AppContext;
    }, [Context, AppContext]);
    const context: ContextType<TState> = useContext(_Context as any);

    const dispatchFuncs = useMemo(() => {
      const dispatchFuncs: { [key: string]: any } = {};
      Object.keys(mapDispatchToProps as any).forEach((key) => {
        const oldFunc = (mapDispatchToProps as any)[key];
        const newFunc = (...args: any) => {
          const dispatchFunc = oldFunc(...args);
          if (typeof dispatchFunc === "object") {
            context.dispatch(dispatchFunc);
          } else {
            const result = dispatchFunc(context.dispatch);
            if (typeof result === "object" && result.then) {
              result.then((dispatchObject?: DispatchObject) => {
                if (dispatchObject && dispatchObject.type) {
                  context.dispatch(dispatchObject);
                }
              });
            }
          }
        };
        dispatchFuncs[key] = newFunc;
      });
      return dispatchFuncs;
    }, [mapDispatchToProps]);

    const props = useMemo(() => {
      return Object.assign(
        {},
        ownProps,
        mapStateToProps(context.state, ownProps),
        dispatchFuncs
      );
    }, [ownProps, context.state]);

    return React.createElement<any>(component, props);
  };
  return React.memo(Connect as any);
}
