import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import React, { Fragment, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LofinoDataTypes } from "../../../../data/models/lofino-data-types";
import { LofinoInputField } from "../../../../data/models/lofino-input-field";
import parser from "../../../../data/util/parser";
import Error from "../default/Error";
import Label from "../default/Label";

interface OwnProps extends LofinoInputField {
  disabled?: boolean;
  lines?: "full" | "inset" | "none";
  lofinoDataType: LofinoDataTypes;
}

const SelectionInput: React.FC<OwnProps> = ({
  label,
  validValues = [],
  defaultValue,
  valueKey,
  info,
  lines,
  disabled,
  lofinoDataType,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const selectRef = React.createRef<HTMLIonSelectElement>();
  const [selected, setSelected] = useState<string | number>(
    parser.parseValue(lofinoDataType, defaultValue)
  );
  const [size, sizeLG] = useMemo(
    () => [
      (12 / validValues.length).toString(),
      (6 / validValues.length).toString(),
    ],
    [validValues]
  );
  const openSelect: React.MouseEventHandler<HTMLIonButtonElement> = (ev) => {
    if (selectRef.current) {
      //;(selectRef.current as any).click(ev)
      (selectRef.current as any).open(ev);
    }
  };
  return (
    <IonItem lines={lines} className="ion-no-padding">
      <IonGrid>
        <IonRow>
          <Label label={label} info={info} valueKey={valueKey} />
          <Controller
            name={valueKey.toString()}
            rules={{
              required: true,
              validate: (val) =>
                validValues?.includes(
                  parser.parseValue(lofinoDataType, val)
                ) === false
                  ? "Bitte wähle einen Wert aus!"
                  : true,
            }}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => {
              if (validValues.length > 6)
                return (
                  <Fragment key={"select-option-" + valueKey + "-row"}>
                    <IonCol size="12" sizeLg="6" className="ion-text-right">
                      <IonButton
                        //style={{ width: '100%' }}
                        className="ion-text-right"
                        onClick={openSelect}
                        fill="clear"
                        disabled={disabled}
                      >
                        <IonLabel
                          color="dark"
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          {value}
                        </IonLabel>
                        <IonIcon slot="end" icon={chevronDownOutline} />
                      </IonButton>
                      <IonSelect
                        ref={selectRef}
                        hidden
                        style={{
                          width: "100%",
                          height: "2rem",
                        }}
                        interface="popover"
                        value={value}
                        onIonChange={onChange}
                      >
                        {validValues.map((v) => (
                          <IonSelectOption
                            key={"select-option-" + valueKey + "-" + v}
                            value={v}
                          >
                            {v}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonCol>
                  </Fragment>
                );
              return (
                <Fragment key={"select-option-" + valueKey + "-row"}>
                  {validValues.map((val) => {
                    return (
                      <IonCol
                        key={"select-option-" + valueKey + "-" + val}
                        size={size}
                        sizeLg={sizeLG}
                      >
                        <IonButton
                          disabled={disabled}
                          expand="block"
                          fill={val === selected ? "solid" : "outline"}
                          color={val === selected ? "primary" : "medium"}
                          onClick={() => {
                            setSelected(val);
                            onChange(val);
                          }}
                        >
                          <span
                            style={{ fontWeight: 600 }}
                            className="data-selection-button-label"
                          >
                            {val}
                          </span>
                        </IonButton>
                      </IonCol>
                    );
                  })}
                </Fragment>
              );
            }}
          />
          {errors[valueKey.toString()] && (
            <IonCol>
              <Error name={valueKey.toString()} />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default SelectionInput;
