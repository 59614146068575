import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonSpinner,
  useIonAlert,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  lockClosedOutline,
  refreshCircleOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { AuthState } from "../../../data/user/user.state";
import { connect } from "../../../data/util/connect";
import { Button, ButtonContainer } from "../Login";
import styled from "styled-components";
import { InputWrapper } from "./Mail.input";

interface OwnProps {
  disabled?: boolean;
  loading?: boolean;
  value?: string;
  setValue: (value: string) => any;

  onEnterPressed?: () => any;
  submitButtonHidden?: boolean;
  onResend?: () => any;
}

interface StateProps {
  authState: AuthState;
}

const CodeInput: React.FC<OwnProps & StateProps> = ({
  value = "",
  setValue,
  authState,
  onEnterPressed = () => null,
  submitButtonHidden,
  onResend,
  loading = false,
}) => {
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation("pages", { keyPrefix: "login" });
  return (
    <CodeContainer>
      <span>Code</span>
      <InputWrapper>
        <IonItem lines="none" style={{ borderRadius: 10 }}>
          <IonIcon slot="start" size="small" icon={lockClosedOutline} />

          <IonInput
            placeholder={t("codeInputPlaceholder")}
            disabled={
              authState === AuthState.Unauthenticated ||
              authState === AuthState.Reauthentication ||
              loading
            }
            value={value}
            onIonChange={(e) => setValue(e.detail.value as string)}
            type="email"
            onKeyDown={({ key }) => {
              if (key.toLowerCase().includes("enter")) onEnterPressed();
            }}
          />
          <IonButtons hidden={submitButtonHidden} slot="end">
            {onResend && !loading && !value && (
              <IonButton
                hidden={submitButtonHidden}
                disabled={!!value}
                onClick={() =>
                  presentAlert({
                    header: "Code erneut senden",
                    message: "Möchtest Du den Code nochmal erhalten?",
                    buttons: [
                      {
                        text: "Nochmal senden",
                        role: "submit",
                        handler: onResend,
                      },
                      { text: "Abbrechen", role: "cancel" },
                    ],
                  })
                }
              >
                Erneut senden
                <IonIcon slot="icon-only" icon={refreshCircleOutline} />
              </IonButton>
            )}
            {value && !loading && (
              <IonButton
                hidden={submitButtonHidden}
                disabled={!value}
                onClick={onEnterPressed}
              >
                <IonIcon slot="icon-only" icon={arrowForwardCircleOutline} />
              </IonButton>
            )}
          </IonButtons>
          {loading && <IonSpinner slot="end" />}
        </IonItem>
      </InputWrapper>

      <ButtonContainer>
        <Button
          hidden={submitButtonHidden || loading}
          disabled={!value || authState === AuthState.Loading}
          onClick={onEnterPressed}
          style={{ marginTop: 30 }}
        >
          Weiter
        </Button>
      </ButtonContainer>
    </CodeContainer>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    authState: state.user.authState,
  }),
  component: CodeInput,
});

const CodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
