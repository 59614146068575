import {
    IonApp,
    IonLoading,
    IonRouterOutlet,
    setupIonicReact,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary'
import GlobalMessages from './components/GlobalMessages'

import { AppContextProvider } from './data/AppContext'
import { SystemError } from './data/models/system-error'
import { loadUserFromStorage } from './data/user/user.actions'
import { AuthState } from './data/user/user.state'
import { connect } from './data/util/connect'
import { initQueryParams } from './data/util/queryparams'
import QueryParamError from './pages/error/QueryParamError'
//Pages
import CollectionPage from './pages/formular/Formular'
import LoginPage from './pages/login/Login'
import ProfilePage from './pages/profile/Profile'
import './theme/main.css'
/* Theme variables */
import './theme/variables.css'
/* Dark Mode variables */
//import "./theme/variables-dark-mode.css";

setupIonicReact({
    mode: 'ios',
})

const App: React.FC = () => {
    return (
        <AppContextProvider>
            <GlobalMessages />
            <IonReactRouter>
                <AppContentConnected />
            </IonReactRouter>
        </AppContextProvider>
    )
}

interface StateProps {
    authState: AuthState
    systemError?: SystemError
}
interface DispatchProps {
    loadUserFromStorage: typeof loadUserFromStorage
}

const AppContent: React.FC<StateProps & DispatchProps> = ({
    authState,
    loadUserFromStorage,
    systemError,
}) => {
    //const { ready } = useTranslation()

    useEffect(() => {
        initQueryParams()
        loadUserFromStorage()
    }, [loadUserFromStorage, initQueryParams])

    if (systemError === SystemError.QUERY_PARAM_ERROR)
        return <QueryParamError />
    if (systemError === SystemError.QUERY_PARAM_TOKEN_ERROR)
        return <QueryParamError hasTokenError={true} />

    if (authState === AuthState.Loading)
        return <IonLoading isOpen={true}></IonLoading>

    if (authState !== AuthState.Authenticated)
        return (
            <ErrorBoundary>
                <LoginPage />
            </ErrorBoundary>
        )

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route exact path="/collection">
                        <ErrorBoundary>
                            <CollectionPage />
                        </ErrorBoundary>
                    </Route>
                    <Route exact path="/profile">
                        <ErrorBoundary>
                            <ProfilePage />
                        </ErrorBoundary>
                    </Route>
                    <Route exact path="/">
                        <Redirect to="/collection" />
                    </Route>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    )
}

export default App

const AppContentConnected = connect<{}, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
        authState: state.user.authState,
        systemError: state.system.error,
    }),
    mapDispatchToProps: {
        loadUserFromStorage,
    },
    component: AppContent,
})
