import * as yup from 'yup'
import { Benefit } from '../models/benefit'
import { LofinoInputBlock } from '../models/lofino-input-block'
import { ResponseMessage } from '../models/ResponseMessage'
import Api from '../util/api'

const GET_FIELDS = async () => {
    try {
        return Api.GET<LofinoInputBlock[]>('/calc', {
            validationSchema: yup.array().of(
                yup.object().shape({
                    type: yup.string(),
                    info: yup.string().optional().nullable(),
                    label: yup.string().optional().nullable(),
                    start: yup.number().optional().nullable(),
                    end: yup.number().optional().nullable(),
                    inputs: yup.array().of(
                        yup.object().shape({
                            info: yup.string().optional().nullable(),
                            label: yup.string().optional().nullable(),
                            type: yup.string(),
                            valueKey: yup.number().required(),
                            defaultValue:
                                yup.string().optional().nullable() ||
                                yup.number().optional().nullable(),
                            unit: yup.string().optional().nullable(),
                            min: yup.number().optional().nullable(),
                            max: yup.number().optional().nullable(),
                            budgetDependencyValueKey: yup
                                .number()
                                .optional()
                                .nullable(),
                            expert: yup.boolean().optional().nullable(),
                            validValues: yup.array().optional().nullable(),
                        }),
                    ),
                }),
            ),
        })
    } catch (e: any) {
        if (e instanceof ResponseMessage) return new ResponseMessage(e)
        return new ResponseMessage({ headers: null!, text: e })
    }
}

const CALCULATE = async (body: { valueKey: number; value: any }[]) => {
    try {
        return Api.POST<Benefit>('/calc', {
            body,
            validationSchema: yup.array().of(
                yup.object().shape({
                    info: yup.string().optional().nullable(),
                    label: yup.string().optional().nullable(),
                    oldValue: yup
                        .mixed()
                        .test(
                            (v) =>
                                typeof v === 'string' ||
                                typeof v === 'number' ||
                                v === null,
                        ),
                    newValue: yup
                        .mixed()
                        .test(
                            (v) =>
                                typeof v === 'string' ||
                                typeof v === 'number' ||
                                v === null,
                        ),
                    type: yup
                        .mixed()
                        .oneOf([
                            'header',
                            'space',
                            'float',
                            'string',
                            'info',
                            'line',
                        ])
                        .required(),
                    hidden: yup.boolean().nullable(),
                    unit: yup.string().nullable(),
                    style: yup
                        .object()
                        .shape({
                            fontWeight: yup.mixed().oneOf(['bold', null]),
                            color: yup.mixed().oneOf(['green', null]),
                        })
                        .nullable(),
                }),
            ),
        })
    } catch (e: any) {
        if (e instanceof ResponseMessage) return new ResponseMessage(e)
        return new ResponseMessage({ headers: null!, text: e })
    }
}

export default { GET_FIELDS, CALCULATE }
