import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonProgressBar,
  IonToolbar,
} from "@ionic/react";
import { personCircleOutline } from "ionicons/icons";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorContent from "../../components/ErrorContent";
import LoadingBenefit from "../../components/LoadingBenefit";
import {
  calculateBenefit,
  clearBenefit,
  loadFields,
} from "../../data/formular/formular.actions";
import FormularContext from "../../data/formular/formular.context";
import formularReducer from "../../data/formular/formular.reducer";
import FormularState, {
  FormularStateEnum,
  initialFormularState,
} from "../../data/formular/formular.state";
import { CustomerData } from "../../data/models/customer-data";
import {
  presentGlobalAlert,
  presentGlobalToast,
} from "../../data/system/system.actions";
import { loadCustomerData } from "../../data/user/user.actions";
import { TokenLocation } from "../../data/user/user.state";
import { connect } from "../../data/util/connect";
import ContextProvider from "../../data/util/ContextProvider";
import parser from "../../data/util/parser";
import ColorConverter from "../../helpers/ColorConverter";
import "../styles/Collection.scss";
import Block from "./components/Block";
import ExpertToggle from "./components/ExpertToggle";
import Result from "./components/Result";
import "./Formular.scss";
import { Logo } from "../../assets/logo";
import styled from "styled-components";
import { Button, ButtonContainer } from "../login/Login";

interface StatePropsFormularState extends FormularState {}

interface OwnProps extends FormularDispatchProps, FormularStateProps {}

interface DispatchPropsAppState {
  presentGlobalToast: typeof presentGlobalToast;
  presentGlobalAlert: typeof presentGlobalAlert;
}

interface DispatchPropsFormularState {
  calculateBenefit: typeof calculateBenefit;
  loadFields: typeof loadFields;
  clearBenefit: typeof clearBenefit;
}

const CollectionJSX: React.FC<
  DispatchPropsAppState &
    DispatchPropsFormularState &
    StatePropsFormularState &
    OwnProps
> = ({
  presentGlobalToast,
  presentGlobalAlert,
  loadFields,
  calculateBenefit,
  clearBenefit,
  formularState,
  error,
  blocks = [],
  benefit,
  customerData,
  tokenLocation,
}) => {
  const { t } = useTranslation("pages", { keyPrefix: "form" });
  //const [expert, toggleExpert] = useToggle()
  const [expert, setExpert] = useState(false);

  const processing = useMemo(
    () =>
      formularState === FormularStateEnum.Loading ||
      formularState === FormularStateEnum.LoadingBenefit ||
      formularState === FormularStateEnum.LoadingFields,
    [formularState]
  );

  const colors = useMemo(
    () =>
      ColorConverter.generateShades(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--ion-color-primary"
        ),
        blocks.length
      ),
    [blocks, ColorConverter.generateShades]
  );

  useEffect(() => {
    if (error) {
      if (formularState === FormularStateEnum.FieldError)
        presentGlobalToast({
          header: t("apiErrorHeader"),
          message: t("apiErrorMessage"),
          color: "danger",
          duration: 5000,
          buttons: [{ text: t("apiAck"), role: "submit" }],
        });
      if (formularState === FormularStateEnum.BenefitError)
        presentGlobalAlert({
          header: "Bei der Berechnung ist ein Fehler aufgetreten",
          buttons: [{ text: "Okay", role: "submit" }],
        });
    }
  }, [formularState, error, presentGlobalToast, presentGlobalAlert, t]);

  useEffect(() => {
    loadFields();
  }, [loadFields]);

  const methods = useForm({ mode: "all" });
  const { handleSubmit, reset } = methods;

  const handleSubmitForm = handleSubmit(
    async (values: { [key: string]: string }) => {
      console.log("handle Submit");
      console.log(values);

      try {
        const payload = parser.parseFormResponse(values, blocks);
        calculateBenefit(payload);
      } catch (e) {
        console.log("error while submit", e);
        presentGlobalAlert({
          header: "Fehler bei der Verarbeitung der Daten",
          buttons: [{ text: "Okay", role: "submit" }],
        });
      }
    }
  );

  return (
    <FormProvider {...methods}>
      <form className="ion-no-padding" onSubmit={handleSubmitForm}>
        <IonPage id="collection-page">
          <LoadingBenefit
            loading={
              processing && formularState === FormularStateEnum.LoadingBenefit
            }
          />
          <IonHeader>
            <IonToolbar>
              {/*
                Commented out logic for whitelabeling. This use case does not exist currently, however might occur in the future - thus, commented out and not deleted. 
                 */}
              {/* {customerData && customerData.logo && (
                <img
                  slot="start"
                  style={{ height: 45 }}
                  alt="logo"
                  src={
                    customerData.logo.includes("data:image")
                      ? customerData.logo
                      : "data:image/svg+xml;base64," + customerData.logo
                  }
                />
              )} */}
              <Logo />

              <IonAvatar slot="start"></IonAvatar>
              <IonButtons
                slot="end"
                hidden={tokenLocation === TokenLocation.TEMP}
              >
                <IonButton routerLink="/profile">
                  <IonIcon size="large" icon={personCircleOutline} />
                </IonButton>
              </IonButtons>

              {/* <IonTitle style={{ fontSize: 24 }}>
                {(customerData && customerData.name) || "Vorteilsrechner"}
              </IonTitle> */}
            </IonToolbar>
            <IonProgressBar
              style={{
                "--background": processing
                  ? "var(--ion-color-primary)"
                  : "transparent",
                "--progress-background": processing
                  ? "var(--ion-color-primary)"
                  : "transparent",
              }}
              type="indeterminate"
            />
          </IonHeader>
          <IonLoading
            isOpen={
              processing && formularState !== FormularStateEnum.LoadingBenefit
            }
            message="Lade Daten..."
          />
          <IonContent fullscreen>
            <div style={{ margin: "auto", maxWidth: 1000 }}>
              <div hidden style={{ textAlign: "center" }}>
                Du befindest Dich zurzeit in der{" "}
                {expert ? "Experten" : "Einfachen"} Ansicht
              </div>
              <ExpertToggle checked={expert} onChange={setExpert} />
              {formularState === FormularStateEnum.FieldError && (
                <ErrorContent message={t("apiErrorMessage")}>
                  <IonButton
                    expand="block"
                    fill="clear"
                    onClick={() => {
                      loadFields();
                      loadCustomerData();
                    }}
                  >
                    <IonLabel>{t("apiRetry")}</IonLabel>
                  </IonButton>
                </ErrorContent>
              )}
              {blocks.map((block, index) => (
                <Block
                  key={"block-" + index}
                  block={block}
                  isFirst={index === 0}
                  isLast={index === blocks.length}
                  expert={expert}
                  color={colors[index]}
                />
              ))}
              {benefit && formularState === FormularStateEnum.Output && (
                <Result benefit={benefit} closeResult={() => clearBenefit()} />
              )}
            </div>
          </IonContent>

          <IonToolbar className="ion-no-padding">
            <div
              style={{
                margin: "auto",
                maxWidth: 900,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <ButtonContainer>
                <ResetButton
                  onClick={() => {
                    clearBenefit();
                    reset();
                  }}
                  disabled={processing}
                >
                  Zurücksetzen
                </ResetButton>
                <Button
                  type="submit"
                  disabled={
                    //!formState.isValid ||
                    processing
                  }
                  //disabled={!formState.isValid/*  || !formState.isDirty */}
                >
                  {" "}
                  {t("calculateAdvantages")}
                </Button>
              </ButtonContainer>
            </div>
          </IonToolbar>
        </IonPage>
      </form>
    </FormProvider>
  );
};

const CollectionPage = connect<OwnProps, {}, DispatchPropsAppState>({
  mapDispatchToProps: {
    presentGlobalToast,
    presentGlobalAlert,
  },
  component: connect<
    {},
    StatePropsFormularState,
    DispatchPropsFormularState,
    FormularState
  >({
    Context: FormularContext,
    component: CollectionJSX,
    mapStateToProps: (state) => state,
    mapDispatchToProps: {
      loadFields,
      calculateBenefit,
      clearBenefit,
    },
  }),
});

interface FormularStateProps {
  customerData?: CustomerData;
  tokenLocation: TokenLocation;
}

interface FormularDispatchProps {
  loadCustomerData: typeof loadCustomerData;
}

const Formular: React.FC<FormularDispatchProps & FormularStateProps> = (
  props
) => {
  /* if (!customerData)
        return (
            <IonPage>
                <IonContent>ERROR</IonContent>
            </IonPage>
        ) */
  return (
    <ContextProvider
      Context={FormularContext}
      reducer={formularReducer}
      initialState={initialFormularState}
    >
      <CollectionPage {...props} />
    </ContextProvider>
  );
};

export default connect<{}, FormularStateProps, FormularDispatchProps>({
  component: Formular,
  mapDispatchToProps: {
    loadCustomerData,
  },
  mapStateToProps: (state) => ({
    customerData: state.user.customerData,
    tokenLocation: state.user.tokenLocation,
  }),
});

const ResetButton = styled.button`
  padding: 10px 30px;
  border-radius: 5px;
  order-radius: 10px;
  border: 3px solid #f26174;
  color: #f26174;
  font-size: 15px;
  font-weight: 500;
  max-width: 200px;
  &:disabled {
    background: #acb6bf;
    cursor: disabled;
  }
  &:hover:enabled {
    background: #f26174;
    color: #fff;
  }
`;
