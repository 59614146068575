import {
    IonButton,
    IonCard,
    IonCardContent,
    IonGrid,
    IonIcon,
    IonRow,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import React, { useEffect, useMemo } from 'react'
import { Benefit } from '../../../data/models/benefit'
import Header from './default/Header'
import Info from './default/Info'
import LineOutput from './output/Line.output'
import SpaceOutput from './output/Space.output'
import ValueOutput from './output/Value.output'

interface OwnProps {
    benefit?: Benefit
    closeResult?: () => any
}

const DataResult: React.FC<OwnProps> = ({
    benefit = [],
    closeResult = () => null,
}) => {
    const cardRef = React.createRef<HTMLIonCardElement>()
    const _benefit = useMemo(() => {
        if (benefit && Array.isArray(benefit)) return benefit
        return []
    }, [benefit])
    const scrollIntoView = () => {
        if (cardRef.current) {
            const content = document.querySelector('ion-content')
            if (content) {
                content.scrollToPoint(
                    0,
                    cardRef.current.getBoundingClientRect().bottom,
                    750,
                )
            }
        }
    }
    useEffect(() => {
        setTimeout(scrollIntoView, 100)
    }, [scrollIntoView])
    return (
        <IonCard ref={cardRef}>
            <div
                style={{ height: 5, background: 'var(--ion-color-success)' }}
            />
            <IonRow hidden className="ion-text-right">
                <IonButton fill="clear">
                    <IonIcon size="large" icon={closeOutline} color="danger" />
                </IonButton>
            </IonRow>
            <IonCardContent>
                <div
                    hidden
                    onClick={closeResult}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 30,
                        cursor: 'pointer',
                        height: 10,
                        width: -10,
                    }}
                >
                    <IonIcon size="large" icon={closeOutline} color="danger" />
                </div>

                <IonGrid className="ion-no-padding">
                    <Header label="Ihr Vorteil" />
                    {_benefit.map((b, i) => {
                        switch (b.type) {
                            case 'header':
                                return (
                                    <Header key={b.type + i} label={b.label} />
                                )
                            case 'float':
                                return (
                                    <ValueOutput key={b.type + i} benefit={b} />
                                )
                            case 'space':
                                return <SpaceOutput key={b.type + i} />
                            case 'string':
                                return (
                                    <ValueOutput key={b.type + i} benefit={b} />
                                )
                            case 'info':
                                return (
                                    <Info
                                        key={b.type + i}
                                        label={b.label || ''}
                                        defaultValue={b.oldValue || ''}
                                    />
                                )
                            case 'line':
                                return <LineOutput key={b.type + i} />
                            default:
                                return null
                        }
                    })}
                </IonGrid>
            </IonCardContent>
            <div
                style={{ height: 5, background: 'var(--ion-color-success)' }}
            />
        </IonCard>
    )
}

export default DataResult
