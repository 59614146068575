import { IonBackdrop, IonSpinner } from '@ionic/react'
import React, { Suspense } from 'react'

const size = 64 * 2 * 2

interface OwnProps {
    loading?: boolean
}

const LoadingAnimationSvg = React.lazy(() => import('./CalculatorAnimation'))

const LoadingBenefit: React.FC<OwnProps> = ({ loading = true }) => {
    if (!loading) return null
    return (
        <>
            <IonBackdrop
                style={{ opacity: '0.4', zIndex: 999 }}
                tappable={false}
            />
            <div
                className="justify-center"
                style={{
                    position: 'absolute',
                    width: '100%',
                    zIndex: 1000,
                }}
            >
                <Suspense fallback={<IonSpinner color="light" />}>
                    <LoadingAnimationSvg size={size} />
                </Suspense>
            </div>
        </>
    )
}

export default LoadingBenefit

/**
 * return (
        <IonModal
            animated={false}
            isOpen={loading}
            style={{ '--border-radius': '100%' }}
            backdropDismiss={false}
        >
            <IonContent scrollY={false}>
                <object
                    type="image/svg+xml"
                    data="assets/svg/Calculator_animated.svg"
                />
            </IonContent>
        </IonModal>
    )
 */
