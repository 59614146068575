import { ErrorMessage } from '@hookform/error-message'
import { IonText } from '@ionic/react'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import './Error.scss'

interface OwnProps {
    name: string
}

const Error: React.FC<OwnProps> = ({ name }) => {
    const {
        formState: { errors },
    } = useFormContext()
    useEffect(() => {
        if (errors[name]) console.log(name, errors[name])
    }, [errors[name]])
    if (!errors[name]) return null
    return (
        <ErrorMessage
            name={name}
            errors={errors}
            render={({ message }) => {
                if (!message) return null
                return (
                    <div className="form-error-text-div-container">
                        <IonText className="form-error-text">{message}</IonText>
                    </div>
                )
            }}
        />
    )
}

export default Error
