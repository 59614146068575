import {
    IonContent,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonText,
} from '@ionic/react'
import React from 'react'

const LICENSES = [
    {
        name: 'Calculator Icon',
        author: 'Boca Tutor',
        license: 'CC BY 3.0',
        licenseUrl: 'https://creativecommons.org/licenses/by/3.0/',
        authorUrl: 'https://www.iconfinder.com/bocatutor',
        item: (
            <IonImg
                style={{
                    height: 64,
                    width: 64,
                    marginTop: 10,
                    marginBottom: 10,
                }}
                slot="start"
                src="assets/svg/Calculator.svg"
                alt="licensed-calculator"
            />
        ),
        modification: 'Slightly modified for the loading animation.',
    },
]

const Licenses: React.FC = () => {
    return (
        <IonContent>
            <IonList>
                {LICENSES.map((l) => (
                    <IonItem>
                        {l.item}
                        <div>
                            <IonLabel>
                                <IonText style={{ fontWeight: 'bold' }}>
                                    {l.name}
                                </IonText>
                                <IonText style={{ fontWeight: 100 }}>
                                    {' by '}
                                </IonText>
                                <a href={l.authorUrl} target="_blank">
                                    {l.author}
                                </a>
                            </IonLabel>
                            <IonLabel>
                                <IonText
                                    style={{ fontSize: 14, fontWeight: 100 }}
                                >
                                    {'licensed under '}
                                </IonText>
                                <a
                                    style={{ fontSize: 14 }}
                                    href={l.licenseUrl}
                                    target="_blank"
                                >
                                    {l.license}
                                </a>
                            </IonLabel>
                            <IonLabel style={{ marginTop: 10 }}>
                                <IonText style={{ fontSize: 12 }}>
                                    {l.modification}
                                </IonText>
                            </IonLabel>
                        </div>
                    </IonItem>
                ))}
            </IonList>
        </IonContent>
    )
}

export default Licenses
