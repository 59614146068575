import React from 'react'
import { ContextType } from './types'

interface OwnProps {
    Context: React.Context<ContextType<any>>
    initialState: any
    reducer: React.Reducer<any, any>
}

const ContextProvider: React.FC<OwnProps> = ({
    Context,
    initialState,
    reducer,
    children,
}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
        <Context.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export default ContextProvider
