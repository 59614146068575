import { AlertOptions, ToastOptions } from '@ionic/core'
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions'
import { SystemError } from '../models/system-error'
import { ActionType } from '../util/types'

export const presentGlobalToast = (toast: ToastOptions & HookOverlayOptions) =>
    ({
        type: 'system-state-toast',
        toast,
    } as const)

export const presentGlobalAlert = (alert: AlertOptions & HookOverlayOptions) =>
    ({
        type: 'system-state-alert',
        alert,
    } as const)

export const removeGlobalMessage = (type: 'alert' | 'toast') =>
    ({
        type: 'system-state-remove-message',
        _type: type,
    } as const)

export const setSystemError = (error?: SystemError) =>
    ({
        type: 'system-state-error',
        error,
    } as const)

export type SystemActions =
    | ActionType<typeof presentGlobalAlert>
    | ActionType<typeof presentGlobalToast>
    | ActionType<typeof removeGlobalMessage>
    | ActionType<typeof setSystemError>
