import React, { createContext, useReducer } from 'react'
import { AppState, initialState, reducers } from './state'
import { ContextType } from './util/types'

export var StateContext: any = undefined

export const AppContext = createContext<ContextType<AppState>>({
    state: initialState,
    dispatch: () => undefined,
})

export const AppContextProvider: React.FC = (props) => {
    const [store, dispatch] = useReducer(reducers, initialState)
    StateContext = { dispatch, state: store }

    return (
        <AppContext.Provider
            value={{
                state: store,
                dispatch,
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}
