import { IonBackdrop, IonToast } from '@ionic/react'
import React, { useCallback, useEffect, useState } from 'react'

interface OwnProps {
    hasAgreed?: () => any
    disabled?: boolean
}

const CookieMessage: React.FC<OwnProps> = ({
    hasAgreed = () => null,
    disabled,
}) => {
    const [hasAgreedToCookies, setHasAgreedToCookies] = useState(
        //localStorage.getItem('cookies') === 'true',
        disabled,
    )
    const agreeToCookies = useCallback(() => {
        //localStorage.setItem('cookies', 'true')
        setHasAgreedToCookies(true)
    }, [setHasAgreedToCookies, localStorage, hasAgreed])
    useEffect(() => {
        //console.log('cookies agreed', hasAgreedToCookies)
        if (hasAgreedToCookies) hasAgreed()
    }, [hasAgreedToCookies, hasAgreed])

    return (
        <>
            {!hasAgreedToCookies && (
                <IonBackdrop style={{ opacity: '0.4' }} tappable={false} />
            )}
            <IonToast
                position="bottom"
                header="Essenziellen Cookies zustimmen"
                message="Um den LOFINO Vorteilsrechner nutzen zu können, müssen Sie der Nutzung essenzieller Cookies zustimmen. Diese nutzen wir, um die Authentifizierung per Email zu ermöglichen."
                buttons={[
                    {
                        role: 'submit',
                        text: 'Zustimmen',
                        handler: agreeToCookies,
                    },
                ]}
                isOpen={!hasAgreedToCookies}
            />
        </>
    )
}

export default CookieMessage
