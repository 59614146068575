import { useState } from 'react'

function useToggle(initialValue: boolean = false) {
    const [state, setState] = useState(initialValue)
    return [state, () => setState((cur) => !cur)] as [
        state: boolean,
        toggle: () => void,
    ]
}

export default useToggle
