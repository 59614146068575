type HexColor = string
type RgbColor = [r: number, g: number, b: number]
type Color = HexColor | RgbColor

const hexToRgb = (hex: string) => [
    parseInt(hex[1] + hex[2], 16),
    parseInt(hex[3] + hex[4], 16),
    parseInt(hex[5] + hex[6], 16),
]

const convertToHex = (colorPiece: number) => {
    const _color = colorPiece.toString(16)
    return _color.length == 1 ? '0' + _color : _color
}

const rgbToHex = ([r, g, b]: RgbColor) => {
    //console.log('r,g,b', r, g, b)
    return '#' + convertToHex(r) + convertToHex(g) + convertToHex(b)
}

const applyColorThreshold = (num: number, threshold = 255) => {
    return num > 255 ? 255 : num
}

function generateShades(color: Color, numberOfShades: number = 3): HexColor[] {
    //
    //console.log('input color', color)
    const [r, g, b] = typeof color === 'string' ? hexToRgb(color) : color
    const step = 255 / (255 * 10)
    //console.log('max and step', step)
    return Array.apply(null, new Array(numberOfShades)).map((_, i) => {
        //console.log('in new Array', i)
        return rgbToHex([
            applyColorThreshold(Math.floor(((i + 6) * r) / 10)),
            applyColorThreshold(Math.floor(((i + 6) * g) / 10)),
            applyColorThreshold(Math.floor(((i + 6) * b) / 10)),
        ])
    })
}

function padZero(str: string, len: number = 2) {
    len = len || 2
    var zeros = new Array(len).join('0')
    return (zeros + str).slice(-len)
}

function invertColor(hex: string) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.')
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16)
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b)
}

function calculateBrightness([r, g, b]: RgbColor) {
    return Math.sqrt(r * r * 0.241 + g * g * 0.691 + b * b * 0.068)
}

function textColor(brightness: number): RgbColor {
    if (brightness > 130) return [0, 0, 0]
    else return [255, 255, 255]
}

function generateTextColor(color: Color) {
    const _color = Array.isArray(color) ? color : hexToRgb(color)

    return textColor(calculateBrightness(_color as any))
}

export default {
    generateShades,
    hexToRgb,
    invertColor,
    generateTextColor,
    rgbToHex,
}
