import React from 'react'

const LineOutput: React.FC = () => (
    <div
        style={{
            height: '1px',
            backgroundColor: 'lightgrey',
            margin: 2.5,
        }}
    />
)

export default LineOutput
