export class ResponseMessage {
  headers: Headers;
  text: string;

  constructor({ headers, text }: ResponseMessage) {
    console.log("NEW Response Message", text, headers)
    this.headers = headers;
    this.text = text
  }
}
