import { CustomerData } from '../models/customer-data'

export enum AuthState {
    CodeRequired = 'CodeRequired',
    Reauthentication = 'Reauthentication',
    Authenticated = 'Authenticated',
    Unauthenticated = 'Unauthenticated',
    Loading = 'Loading',
}

export enum TokenLocation {
    STORAGE = 'STORAGE',
    TEMP = 'TEMP',
}

export interface UserState {
    authState: AuthState
    username?: string
    customerData?: CustomerData
    tokenLocation: TokenLocation
}
