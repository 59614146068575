import { ToastOptions } from "@ionic/core";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";
import i18next from "i18next";

const GlobalToasts = {
  userLoggedIn: ({
    email,
  }: {
    email: string;
  }): ToastOptions & HookOverlayOptions => {
    const t = i18next.getFixedT(null, "responses", "userLoggedIn");
    return {
      header: t("header", { name: email }),
      message: t("message"),
      position: "top",
      duration: 2000,
      buttons: [{ text: "OK", role: "submit" }],
    };
  },
  submitUsernameSuccess: (email: string): ToastOptions & HookOverlayOptions => {
    const t = i18next.getFixedT(null, "responses", "emailSuccess");
    return {
      header: t("header", { email }),
      message: t("message"),
      buttons: [{ text: t("ack"), role: "submit" }],
      position: "top",
      duration: 10000,
      color: "success",
    };
  },
};

export default GlobalToasts;
