import { Benefit } from '../models/benefit'
import { LofinoInputBlock } from '../models/lofino-input-block'
import { ResponseMessage } from '../models/ResponseMessage'

export enum FormularStateEnum {
    Loading = 'Loading',
    LoadingFields = 'LoadingFields',
    LoadingBenefit = 'LoadingBenefit',
    Input = 'Input',
    Output = 'Output',
    FieldError = 'FieldError',
    BenefitError = 'BenefitError',
}

export const initialFormularState: FormularState = {
    formularState: FormularStateEnum.Loading,
    blocks: [],
}

type FormularState = {
    blocks: LofinoInputBlock[]
    benefit?: Benefit
    formularState: FormularStateEnum
    error?: ResponseMessage
}

export default FormularState
