import { UserActions } from './user.actions'
import { UserState } from './user.state'

export const userReducer = (
    state: UserState,
    action: UserActions,
): UserState => {
    switch (action.type) {
        case 'set-authstate':
            return { ...state, authState: action.state }
        case 'set-username':
            return { ...state, username: action.username }
        case 'set-customer-data':
            return { ...state, customerData: action.data! }
        case 'set-token-location':
            return { ...state, tokenLocation: action.tokenLocation }
    }
}
