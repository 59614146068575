import { IonIcon, IonText } from '@ionic/react'
import { informationSharp } from 'ionicons/icons'
import React from 'react'
import './ErrorContent.scss'

interface OwnProps {
    message: string
}

const ErrorContent: React.FC<OwnProps> = ({
    message,
    children
}) => {
    return (
        <div className='error-content' >
            <div>
            <IonIcon className="error-icon" color="danger" icon={informationSharp} />
            <IonText className='error-message' >{message}</IonText>

            </div>
            {children}
        </div>
    )
}

export default ErrorContent