import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonList,
    IonPage,
    IonRow,
    IonText,
    IonToolbar,
} from '@ionic/react'
import React from 'react'
import { allQueryParamsUsed } from '../../data/util/queryparams'

interface OwnProps {
    hasTokenError?: boolean
}

const tokenInfo = {
    key: 'access_token',
    value: 'The provided token is expired or not valid.',
}

const QueryParamError: React.FC<OwnProps> = ({ hasTokenError }) => {
    const unused = allQueryParamsUsed()
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>ERROR 400</IonToolbar>
            </IonHeader>
            <IonContent>
                <IonText
                    color="danger"
                    style={{ fontSize: 30, fontWeight: 25 }}
                >
                    The following query parameters are incorrect
                </IonText>
                <br />
                <IonList>
                    <IonItem>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonText style={{ fontWeight: 'bold' }}>
                                        KEY
                                    </IonText>
                                </IonCol>
                                <IonCol>
                                    <IonText style={{ fontWeight: 'bold' }}>
                                        VALUE
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                    {hasTokenError && (
                        <IonItem key={tokenInfo.key + tokenInfo.value}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>{tokenInfo.key}</IonCol>
                                    <IonCol>{tokenInfo.value}</IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                    )}
                    {Array.isArray(unused) &&
                        unused.map(([key, value], idx) => {
                            return (
                                <IonItem key={key + value + idx}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>{key}</IonCol>
                                            <IonCol>{value}</IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            )
                        })}
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default QueryParamError
