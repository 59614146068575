export function combineReducers<
  R extends { [key: string]: (...args: any) => any }
>(reducers: R) {
  type keys = keyof typeof reducers;
  type returnType = { [K in keys]: ReturnType<typeof reducers[K]> };
  const combinedReducer = (state: any, action: any) => {
    const newState = {} as returnType;
    const keys = Object.keys(reducers);
    keys.forEach((key: keys) => {
      const result = reducers[key](state[key], action);
      newState[key] = result || state[key];
    });
    return newState;
  };
  return combinedReducer;
}
